export default {
    'en-US': {
        auth: {
            auth: "Authentication",
            authWith: "Authenticate with"
        },
        navBar: {
            uploadDocuments: 'Upload Documents',
            createCertificate: 'Create Certificate',
            showCertificate: 'Show Documents',
            settings: 'Settings',
            signOut: 'Sign out',
            authAs: 'Authenticated as',
        },
        login: {
            setLanguage: 'Set Language',
            password: 'Password',
            login: 'Unlock Key',
            passwordPlaceHolder: 'Please, type your password',
        },
        createSupplier: {
            onboardSupplier: 'Onboard Supplier',
            name: 'Name',
            namePlaceHolder: 'Enter a name',
            street: 'Street',
            streetPlaceHolder: 'Enter a street',
            zipcode: 'Zip code',
            zipcodePlaceHolder: 'Enter a zip code',
            city: 'City',
            cityPlaceHolder: 'Enter a city',
            country: 'Country',
            countryPlaceHolder: 'Enter a country',
            password: 'Password',
            confirmPassword: 'Confirm your password',
            wordcloudCheckBox: 'Generate word cloud phrase automatically',
            wordcloudInfo: 'To generate a custom word cloud phrase you have to fill all six fields of words',
            word: 'Word',
            wordPlaceHolder: 'Secret word',
            wordPhraseCloudGameTitle: 'Word Phrase Cloud Game',
            supplierCreatedTitle: 'Supplier created!',
            wordPhraseInfo: 'Please take note of these information below',
            wordPhraseCloudGameExplanation: 'Put the word cloud correctly and sequentially in the fields below before continuing in the application. It is important to keep these words together with the access link in a safe place so that you can retrieve the registration information when necessary.',
            wordPhraseCloud: 'Word Phrase Cloud',
            linkToAccess: 'Link to access',
            next: 'Next',
            back: 'Back',
            login: 'Log in',
            secretWordGamePlaceHolder: 'Secret word game',
            email: 'Enter an email address',
            emailError: 'not a valid email address'
        },
        createSiemens: {
            onboardSiemens: 'Onboard Siemens',
            name: 'Name',
            namePlaceHolder: 'Enter a name',
            street: 'Street',
            streetPlaceHolder: 'Enter a street',
            zipcode: 'Zip code',
            zipcodePlaceHolder: 'Enter a zip code',
            city: 'City',
            cityPlaceHolder: 'Enter a city',
            country: 'Country',
            countryPlaceHolder: 'Enter a country',
            password: 'Password',
            confirmPassword: 'Confirm your password'
        },
        createCertificate: {
            wordincludeInCertCheckBox: 'Add to certificate',
            formTitle: 'Inspection Certificate 3.1 acc. EN 10204',
            formSupplier: 'Supplier',
            formConsignee: 'Consignee',
            formOrderNumber: 'Order Number',
            formDeliveryNumber: 'Delivery Note Number',
            formAddMaterial: 'Add Material',
            formMaterialFromOrderNum : 'Materials Order Details',
            formEditMaterial: 'Edit Material',
            formOrderPosition: 'Order Position',
            formMaterialNumber: 'Material Number',
            formQuantity: 'Quantity',
            formMaterialName: 'Material Name',
            formSerialNumber: 'Serial Number',
            formOverviewTests: 'Overview Tests',
            formMaintainData: 'Maintain data',
            formInspectorName: 'Inspector Name',
            formInspectionDate: 'Inspection Date',
            formNoteTestsResultTitle: 'Note / Tests Results',
            formNoteTestsResultsText: 'Tests are carried out in accordance with the test plan/technical delivery conditions. The requirements are met. The test results are documented by measurement sheets, test reports, etc. and form the basis of this inspection cerfificate. The objects tested can be assigned to this inspection certificate by means of appropriate identification.',
            formConfirmationTitle: 'Confirmation',
            formConfirmationText: 'The results of the tests performed on our delivery items confirm that the above listed parts comply with the order specifications. All the relevant data / documents will be kept in a safe place for at last 10 years or as contractly agreed. The customer may check the data / documents at any time.',
            formAddMaterialBtn: 'Add Material',
            formCancelEditMaterialBtn: 'Cancel',
            formEditMaterialBtn: 'Edit Material',
            formEditCertificateBtn: 'Edit Certificate',
            formCreateCertificateBtn: 'Continue',
            formCreateInspectionCertificateBtn: 'Create Inspection Certificate',
            formCloseBtn: 'Close',
            formSwitchModeBtn: 'Switch mode',
            formInvalidSerialNumberMessage: 'Invalid serial number.',
            formInvalidOverviewTestsMessage: 'Invalid overview tests.', 
            formSelectConsignee: 'Select the consignee',
            formSelectTester: 'Select the quality inspector',
            formRequiredMessage: ' required.',
            formOrderNumberPlaceHolder: 'Enter a order number',
            formDeliveryNoteNumberPlaceHolder: 'Enter a delivery note number',
            formOrderPositionPlaceHolder: 'Enter a order position',
            formMaterialNumberPlaceHolder: 'Enter a material number',
            formQuantityPlaceHolder: 'Enter a quantity',
            formMaterialNamePlaceHolder: 'Enter a material name',
            formSerialNumberPlaceHolder: 'Enter a serial number',
            formOverviewTestsPlaceHolder: 'Enter an overview tests',
            formInspectorNamePlaceHolder: 'Enter an inspector name',
            selectConsigneeName: 'Name',
            selectConsigneeStreet: 'Street',
            selectConsigneeZip: 'Zip',
            selectConsigneeCity: 'City',
            selectConsigneeCountry: 'Country',
            selectOrderNumberMessage: 'Order number not found',
            uiErrorMessageFailAPICall: 'Error getting data from the backend API. Please use custom mode.',
            uiErrorMessageSupplierNotFound: 'Supplier number not found.'

        },
        showCertificate: {
            formTitle: 'Show Inspection Certificate',
            formOrderNumber: 'Order Number',
            formDeliveryNumber: 'Delivery Note Number',
            formShowCertificatesBtn: 'Show Certificates',
            formTableOrderNumberTh: 'Order Number',
            formTableDeliveryNumberTh: 'Delivery Note Number',
            formTableMaterialQtyTh: 'Position',
            formTableCreationDteTh: 'Creation Date',
            formTableButtonShowPosition: 'Show Positions',
            formTableButtonShowCertificate: 'Show Certificate',
            modalTitle: 'Inspection Certificate 3.1 acc. EN 10204',
            modalSupplier: 'Supplier',
            modalConsignee: 'Consignee',
            modalOrderNumber: 'Order Number',
            modalDeliveryNumber: 'Delivery Note Number',
            modalOrderPosition: 'Order Position',
            modalQuantity: 'Quantity',
            modalMaterialNumber: 'Material Number',
            modalMaterialName: 'Material Name',
            modalSerialNumber: 'Serial Number',
            modalOverviewTests: 'Overview Tests',
            modalInspectorName: 'Inspector Name',
            modalInspectionDate: 'Inspection Date',
            modalNoteTestsResultsTitle: 'Note / Tests Results',
            modalNoteTestsResultsText: 'Tests are carried out in accordance with the test plan/technical delivery conditions. The requirements are met.The test results are documented by measurement sheets, test reports, etc. and form the basis of this inspection cerfificate. The objects tested can be assigned to this inspection certificate by means of appropriate identification.',
            modalConfirmationTitle: 'Confirmation',
            modalConfirmationText: 'The results of the tests performed on our delivery items confirm that the above listed parts comply with the order specifications. All the relevant data / documents will be kept in a safe place for at last 10 years or as contractly agreed. The customer may check the data / documents at any time.',
            modalGeneratePDFBtn: 'Generate PDF',
            modalCloseBtn: 'Close',
            btnShowPositions: 'Show Positions',
            btnHidePositions: 'Hide Positions',
            btnEditCert: 'Create new version'
        },
        uploadDocuments: {
            uploadDocuments: 'Upload Documents',
            formTitle: 'Upload documents',
            formDeliveryNoteNumber: 'Delivery Note Number',
            formOrderNumber: 'Order Number',
            formDeliveryNumber: 'Delivery Note Number',
            formDeliveryNoteNumberPlaceholder: 'Delivery Note Number',
            formOrderNumberPlaceHolder: 'Order Number',
            formProfiles: 'Profiles',
            formOrderPosition: 'Order Position',
            formMaterialNumber: 'Material Number',
            formAttachments: 'Attachments',
            formName: 'Name',
            formQuantity: 'Quantity',
            formMaterialName: 'Material Name',
            formSerialNumber: 'Serial Number',
            formOverviewTests: 'Overview Tests',
            formMaintainData: 'Maintain data',
            formCheckboxSetPlaceHolderValue: ' Set Placeholder Value if no Delivery Note Number is available yet',
            formShowCertificatesBtn: 'Show Certificates',
            addButton: 'Add',
            closeButton: 'Close',
            saveButton: 'Save',
            status: 'Status',
            uiErrorMessageFailTitleAPICall: 'API call error', 
            uiErrorMessageFailAPICall: 'Error getting data from the backend API.',
            showDocsButton: 'Show docs',
            
        },
        searchDocuments: {
            searchDocuments: 'Show documents',
            formTitle: 'Show documents',
            formOrderNumber: 'Order Number',
            formOrderAndDeliverTable: 'Order number / Delivery note number',
            formOrderAndDeliverTableOrders: 'Order number',
            formOrderAndDeliverNotes: 'Delivery note number',
            showDocumentsButton: 'Show Documents',
            apiErrorMessageDownload: 'Could not download the file. Detailed error for developers below.',
            apiErrorMessageGetDocumentProfiles: 'Could not get the document profiles. Detailed error for developers below.',
            apiErrorMessageGetMaterials: 'Could not get the materials. Detailed error for developers below.',
            apiErrorMessageGetDeliveryNotes: 'Could not get the delivery notes. Detailed error for developers below.',
            apiErrorMessageGetOrderNumberPrefix: 'Could not get the order number prefix. Detailed error for developers below.',
            apiErrorMessageGetOrderNumbers: 'Could not get the order numbers. Detailed error for developers below.',
            apiErrorMessageGetDocumentProfileName: 'Could not get the documents profile name',
            apiErrorMessageGetDocumentOrderPositions: 'Could not get the orders positions',
        },
        profile: {
            formTitle: 'Profile',
            formEditTitle: 'Edit Profile',
            fromDescription: 'Add quality inspector',
            formAddMaterialDescription: 'Add new test description',
            formName: 'Name',
            formNamePlaceHolder: 'Enter a name',
            formStreet: 'Street',
            formStreetPlaceHolder: 'Enter a street',
            formZipCode: 'Zip Code',
            formZipCodePlaceHolder: 'Enter a zip code',
            formCity: 'City',
            formCityPlaceHolder: 'Enter a city',
            formCountry: 'Country',
            formCountryPlaceHolder: 'Enter a country',
            formEditSupplier: 'Edit Supplier',
            btnSaveSupplier: 'Save Supplier',
            btnCancel: 'Cancel',
            btnTabHome: 'Home',
            btnTabProfile: 'Profile',
            btnTabTesters: 'Quality inspector',
            btnTabMaterialTests: 'Material tests',
            btnTabSettings: 'Settings',
            btnAddTester: 'Add',
            email: 'Email-address',
            btnAddMaterialTest: 'Add',

        },
        materialTests: {
            formTitle: 'Material tests',
            name: 'Test',
            addTestPlaceHolder: 'Enter test description'
        },
        testers: {
            formTitle: 'Quality inspector',
            name: 'Name',
            addTesterPlaceHolder: 'Enter the name of the tester'
        },
        commonButtons: {
            select: 'Select',
            add: 'Add',
            cancel: 'Cancel',
            edit: 'Edit',
        },
        commonMessages: {
            isRequired: 'is required.',
            loading: 'Loading...',
            saving: 'saving',
            setLanguage: 'Set Language'
        }
    },
    'de-DE': {
        auth: {
            auth: "Anmelden",
            authWith: "Anmelden mit"
        },
        navBar: {
            uploadDocuments: 'Dokumente hochladen',
            createCertificate: 'Abnahmeprüfzeugnis 3.1 erstellen',
            showCertificate: 'Dokumente anzeigen',
            settings: 'Einstellungen',
            signOut: 'Ausloggen',
            authAs: 'Authentifiziert als',
        },
        login: {
            setLanguage: 'Sprache einstellen',
            password: 'Passwort',
            login: 'Entsperren',
            passwordPlaceHolder: 'Geben Sie Ihr Passwort ein',
        },
        createSupplier: {
            onboardSupplier: 'Onboard-Lieferant',
            name: 'Name',
            namePlaceHolder: 'Geben Sie einen Namen ein',
            street: 'Straße',
            streetPlaceHolder: 'Geben Sie eine Straße ein',
            zipcode: 'Postleitzahl',
            zipcodePlaceHolder: 'Geben Sie eine Postleitzahl ein',
            city: 'Stadt',
            cityPlaceHolder: 'Eine Stadt eingeben',
            country: 'Land',
            countryPlaceHolder: 'Ein Land eingeben',
            password: 'Passwort',
            confirmPassword: 'Bestätigen Sie Ihr Passwort',
            wordcloudCheckBox: 'Automatische Generierung von Wortwolkenphrasen',
            wordcloudInfo: 'Um eine benutzerdefinierte Wortwolke zu erstellen, müssen Sie alle sechs Wortfelder ausfüllen.',
            word: 'Wort',
            wordPlaceHolder: 'Geheimwort',
            wordPhraseCloudGameTitle: 'Wortphrase Cloud-Spiel',
            supplierCreatedTitle: 'Lieferant angelegt!',
            wordPhraseInfo: 'Bitte beachten Sie diese Informationen unten.',
            wordPhraseCloudGameExplanation: 'Legen Sie die Wortwolke korrekt und sequentiell in die folgenden Felder, bevor Sie mit der Anwendung fortfahren. Es ist wichtig, diese Wörter zusammen mit dem Zugangslink an einem sicheren Ort aufzubewahren, damit Sie bei Bedarf die Registrierungsinformationen abrufen können.',
            wordPhraseCloud: 'Wortphrase Cloud',
            linkToAccess: 'Link zum Zugang',
            next: 'Weiter',
            back: 'Zurück',
            login: 'Einloggen',
            secretWordGamePlaceHolder: 'Geheimes Wortspiel',
            email: 'Eine email Addresse eingeben'

        },
        createSiemens: {
            onboardSiemens: 'Onboard-Siemens',
            name: 'Name',
            namePlaceHolder: 'Geben Sie einen Namen ein',
            street: 'Straße',
            streetPlaceHolder: 'Geben Sie eine Straße ein',
            zipcode: 'Postleitzahl',
            zipcodePlaceHolder: 'Geben Sie eine Postleitzahl ein',
            city: 'Stadt',
            cityPlaceHolder: 'Eine Stadt eingeben',
            country: 'Land',
            countryPlaceHolder: 'Ein Land eingeben',
            password: 'Passwort',
            confirmPassword: 'Bestätigen Sie Ihr Passwort'
        },
        createCertificate: {
            wordincludeInCertCheckBox: 'Zum Zertifikat hinzufügen',
            formTitle: 'Abnahmeprüfzeugnis 3.1 nach EN 10204',
            formSupplier: 'Lieferant',
            formConsignee: 'Empfänger',
            formOrderNumber: 'Bestellnummer',
            formDeliveryNumber: 'Lieferscheinnummer',
            formAddMaterial: 'Position hinzufügen',
            formMaterialFromOrderNum : 'Materialbestelldetails',
            formEditMaterial: 'Position bearbeiten',
            formOrderPosition: 'Bestellposition',
            formMaterialNumber: 'Materialnummer',
            formQuantity: 'Menge',
            formMaterialName: 'Materialname',
            formSerialNumber: 'Serialnummern',
            formOverviewTests: 'Übersicht der wichtigsten Prüfungen',
            formMaintainData: 'Eingabefelder nach dem hinzufügen nicht leeren',
            formInspectorName: 'Abnahmebeauftragter',
            formInspectionDate: "Abnahmedatum",
            formNoteTestsResultTitle: 'Hinweise / Prüfergebnisse',
            formNoteTestsResultsText: 'Tests werden gemäß Testplan / technischen Lieferbedingungen durchgeführt. Die Anforderungen werden erfüllt. Die Prüfergebnisse werden durch Messblätter, Prüfberichte usw. dokumentiert und bilden die Grundlage dieses Prüfzeugnisses. Die geprüften Gegenstände können diesem Prüfzeugnis durch entsprechende Kennzeichnung zugeordnet werden.',
            formConfirmationTitle: 'Bestätigung',
            formConfirmationText: 'Die Ergebnisse der Tests, die an unseren Liefergegenständen durchgeführt wurden, bestätigen, dass die oben aufgeführten Teile den Bestellspezifikationen entsprechen. Alle relevanten Daten / Dokumente werden für mindestens 10 Jahre oder vertragsgemäß aufbewahrt. Der Kunde kann die Daten / Dokumente jederzeit überprüfen.',
            formAddMaterialBtn: 'Position hinzufügen',
            formCancelEditMaterialBtn: 'Abbrechen',
            formEditMaterialBtn: 'Material bearbeiten',
            formEditCertificateBtn: 'Abnahmeprüfzeugnis 3.1 bearbeiten',
            formCreateCertificateBtn: 'Weiter',
            formCreateInspectionCertificateBtn: 'Abnahmeprüfzeugnis 3.1 erstellen',
            formCloseBtn: 'Schließen',
            formSwitchModeBtn: 'Manueller Modus',
            formInvalidSerialNumberMessage: 'Ungültige Seriennummer.',
            formInvalidOverviewTestsMessage: 'Ungültig: Übersicht über Prüfungen',
            formSelectConsignee: 'Wählen Sie den Empfänger aus',
            formSelectTester: 'Wählen Sie den Tester aus',
            formRequiredMessage: ' erforderlich.',
            formOrderNumberPlaceHolder: 'Geben Sie eine Bestellnummer ein',
            formDeliveryNoteNumberPlaceHolder: 'Geben Sie eine Lieferscheinnummer ein',
            formOrderPositionPlaceHolder: 'Eine Bestellposition eingeben',
            formMaterialNumberPlaceHolder: 'Geben Sie eine Materialnummer ein',
            formQuantityPlaceHolder: 'Geben Sie eine Menge ein',
            formMaterialNamePlaceHolder: 'Einen Materialnamen eingeben',
            formSerialNumberPlaceHolder: 'Geben Sie eine Seriennummer ein',
            formOverviewTestsPlaceHolder: 'Einen Übersichtstest eingeben',
            formInspectorNamePlaceHolder: 'Wählen Sie den Abnahmebeauftragten aus',
            selectConsigneeName: 'Name',
            selectConsigneeStreet: 'Straße',
            selectConsigneeZip: 'Postleitzahl',
            selectConsigneeCity: 'Stadt',
            selectConsigneeCountry: 'Land',
            selectOrderNumberMessage: 'Bestellnummer nicht gefunden',
            uiErrorMessageFailAPICall: 'Fehler beim Abrufen der Daten von der Backend-API. Bitte verwenden Sie den benutzerdefinierten Modus.',
            uiErrorMessageSupplierNotFound: 'Lieferantennummer nicht gefunden.'
        },
        showCertificate: {
            formTitle: 'Abnahmeprüfzeugnis 3.1 anzeigen',
            formOrderNumber: 'Bestellnummer',
            formOrderNumberPlaceHolder: 'Geben Sie eine Bestellnummer ein',
            formDeliveryNumber: 'Lieferschein-Nummer',
            formShowCertificatesBtn: 'Abnahmeprüfzeugnis 3.1 anzeigen',
            formTableOrderNumberTh: 'Auftragsnummer',
            formTableDeliveryNumberTh: 'Lieferschein-Nummer',
            formTableMaterialQtyTh: 'Position',
            formTableCreationDteTh: 'Erstellungsdatum',
            formTableButtonShowPosition: 'Position anzeigen',
            formTableButtonShowCertificate: 'zeige Zertifikat',
            modalTitle: "Abnahmeprüfzeugnis 3.1",
            modalSupplier: 'Lieferant',
            modalConsignee: "Empfänger",
            modalOrderNumber: 'Auftragsnummer',
            modalDeliveryNumber: 'Lieferschein-Nummer',
            modalOrderPosition: 'Bestellposition',
            modalQuantity: 'Menge',
            modalMaterialNumber: 'Materialnummer',
            modalMaterialName: 'Materialname',
            modalSerialNumber: 'Seriennummer',
            modalOverviewTests: 'Übersicht der wichtigsten Prüfungen',
            modalInspectorName: 'Abnahmebeauftragter',
            modalInspectionDate: "Abnahmedatum",
            modalNoteTestsResultsTitle: 'Hinweise / Prüfergebnisse',
            modalNoteTestsResultsText: 'Prüfungen gemäß Prüfplan/ Technischen Lieferbedingungen durchgeführt. Die Anforderungen sind erfüllt. Die Prüfergebnisse sind durch Messblätter, Prüfprotokolle usw. belegt und sind Grundlage dieses Abnahmeprüfzeugnisses. Durch eine entsprechende Kennzeichnung können die geprüften Gegenstände diesem Zeugnis zugeordnet werden.',
            modalConfirmationTitle: 'Bestätigung',
            modalConfirmationText: 'Der Hersteller bestätigt aufgrund von Ergebnissen aus Prüfungen an der Lieferung selbst, dass oben angeführte Gegenstände den Vereinbarungen der Bestellung entsprechen. Alle Prüfdokumente über einen Zeitraum von mindestens 10 Jahren aufbewahrt. Eine Überprüfung der Dokumente durch den Besteller ist zu jeder Zeit möglich.',
            modalGeneratePDFBtn: 'PDF erzeugen',
            modalCloseBtn: 'Schließen',
            btnShowPositions: 'Positionen anzeigen',
            btnHidePositions: 'Positionen ausblenden',
            btnEditCert: 'neue Version erstellen',
            errorDecryption: 'Sie haben nicht die passenden Rechte, um das 3.1 Prüfabnahmezeugnis zu öffnen'

        },
        uploadDocuments: {
            uploadDocuments: 'Dokumente hochladen',
            formTitle: 'Dokumente hochladen',
            formDeliveryNoteNumber: 'Lieferscheinnummer',
            formOrderNumber: 'Bestellnummer',
            formDeliveryNumber: 'Lieferscheinnummer',
            formDeliveryNoteNumberPlaceholder: 'Lieferscheinnummer',
            formOrderNumberPlaceHolder: 'Bestellnummer',
            formProfiles: 'Profile',
            formOrderPosition: 'Bestellposition',
            formMaterialNumber: 'Materialnummer',
            formQuantity: 'Menge',
            formMaterialName: 'Materialbezeichnung',
            formSerialNumber: 'Serial Number',
            formAttachments: 'Anhänge',
            formName: 'Name',
            formOverviewTests: 'Overview Tests',
            formMaintainData: 'Maintain data',
            formCheckboxSetPlaceHolderValue: ' Platzhalterwert setzen, falls die Lieferscheinnummer noch nicht bekannt ist',
            formShowCertificatesBtn: 'Show Certificates',
            addButton: 'Add',
            closeButton: 'Close',
            saveButton: 'Speichern',
            status: 'Status',
            uiErrorMessageFailTitleAPICall: 'API call error', 
            uiErrorMessageFailAPICall: 'Error getting data from the backend API.',
            showDocsButton: 'Dok. Anzeigen',
        },
        searchDocuments: {
            searchDocuments: 'Dokumente anzeigen',
            formTitle: 'Dokumente anzeigen',
            formOrderNumber: 'Bestellnummer',
            formOrderAndDeliverTable: 'Bestellnummer / Lieferscheinnummer',
            formOrderAndDeliverTableOrders: 'Order number',
            formOrderAndDeliverNotes: 'Delivery note number',
            showDocumentsButton: 'Dokumente anzeigen',
            apiErrorMessageDownload: 'Could not download the file. Detailed error for developers below.',
            apiErrorMessageGetDocumentProfiles: 'Could not get the document profiles. Detailed error for developers below.',
            apiErrorMessageGetMaterials: 'Could not get the materials. Detailed error for developers below.',
            apiErrorMessageGetDeliveryNotes: 'Could not get the delivery notes. Detailed error for developers below.',
            apiErrorMessageGetOrderNumberPrefix: 'Could not get the order number prefix. Detailed error for developers below.',
            apiErrorMessageGetOrderNumbers: 'Could not get the order numbers. Detailed error for developers below.',
            apiErrorMessageGetDocumentProfileName: 'Could not get the documents profile name',
            apiErrorMessageGetDocumentOrderPositions: 'Could not get the orders positions',
        },
        profile: {
            formTitle: 'Profil',
            formEditTitle: 'Profil bearbeiten',
            formName: 'Name',
            fromDescription: 'Prüfabnahmebeauftrage hinzufügen',
            formNamePlaceHolder: 'Gib deinen Namen ein',
            formStreet: 'Straße',
            formStreetPlaceHolder: 'Geben Sie Ihre Straße ein',
            formZipCode: 'Postleitzahl',
            formZipCodePlaceHolder: 'Geben Sie Ihre Postleitzahl ein',
            formCity: 'Stadt',
            formCityPlaceHolder: 'Geben Sie Ihre Stadt ein',
            formCountry: 'Land',
            formCountryPlaceHolder: 'Geben Sie Ihr Land ein',
            formEditSupplier: 'Lieferanten bearbeiten',
            btnSaveSupplier: 'Lieferant speichern',
            btnCancel: 'abbrechen',
            btnTabHome: 'Hauptseite',
            btnTabProfile: 'Profil',
            btnTabTesters: 'Prüfabnahmebeauftragte',
            btnTabMaterialTests: 'Materialtests',
            btnTabSettings: 'Einstellungen',
            btnAddTester: 'Hinzufügen',
            email: 'Email-Addresse',
            btnAddMaterialTest: 'Hinzufügen'

        },
        materialTests: {
            formTitle: 'Materialtests',
            name: 'Name',
            addTestPlaceHolder: 'Testbeschreibung eingeben'
        },
        testers: {
            formTitle: 'Prüfabnahmebeauftragte',
            name: 'Name',
            addTesterPlaceHolder: 'Geben Sie den Namen des Prüfabnahmebeauftragten ein.'
        },
        commonButtons: {
            select: 'Wählen',
            add: 'Hinzufügen',
            cancel: 'Stornieren',
            edit: 'Bearbeiten'
        },
        commonMessages: {
            isRequired: 'Wird benötigt',
            loading: 'Laden...',
            saving: 'speichern',
            setLanguage: 'Sprache einstellen'
        }
    }
}
