import React from 'react';
import FormErrors from '../components/FormErrors';
import { Modal, Button, ModalHeader } from 'react-bootstrap';
import LoadingComponent from '../components/LoadingComponent';
import SavingComponent from '../components/SavingComponent';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import UserService from '../services/UserService';
import { FormattedMessage } from 'react-intl';
import Messages from '../messages';
import {MdAdd, MdAttachFile, MdCheckBox, MdCheckBoxOutlineBlank, MdCloudDownload   } from 'react-icons/lib/md';
import {AsyncTypeahead} from 'react-bootstrap-typeahead';

import 'jspdf-autotable';
import Footer from '../components/Footer';

import OidcProvider from '../services/OidcProvider';
import NavBar from '../components/NavBar';

import { ApiService } from '../services/ApiService';

const globalMessages = Messages;
const apiFlagREADY_FOR_UPLOAD = 'READY_FOR_UPLOAD';
const apiFlagFINISHED_PROCESSING = 'FINISHED_PROCESSING';
const apiFlagERROR_PROCESSING = 'ERROR_PROCESSING';
const apiFlagASSIGN_UPLOAD_COMPLETE = 'Upload complete';
const MAX_TIME_WAITED = 10000;
const MAX_PROFILE_NAME_LENGTH = 40;

const certblockAPI = new ApiService();

export class UploadDocumentsContainer extends React.Component {

    constructor(props) {

        super(props);
        this.apiConfig = window.ApiConfig;
        this.deliveryNumberInput = React.createRef();

        this.state = {
            isSiemensOrSupplier: true,
            certificateId: '',
            supplier: '',
            orderNumber: '',
            deliveryNumber: '',
            orderPosition: '',
            materialNumber: '',
            materialDescription: '',
            quantity: '',
            serialNumber: '',
            overviewTests: '',
            nameInspector: '',
            inspectionDate: moment(),
            confirmationText: '',
            confirmation: '',
            checkTestsResults: '',
            materialList: [],
            certificateList: [],
            supplierData: {
                name: '',
                street: '',
                zip: '',
                city: '',
                country: ''
            },
            consigneeIndex: null,
            consigneeData: {
                name: '',
                street: '',
                zip: '',
                city: '',
                country: ''
            },
            expandedRows: [],
            showModal: false,
            showLoadingModal: false,
            showSavingModal: false,
            supplierValid: true,
            orderNumberValid: false,
            deliveryNumberValid: false,
            orderPositionValid: true,
            materialNumberValid: false,
            materialDescriptionValid: false,
            quantityValid: false,
            serialNumberValid: true,
            overviewTestsValid: true,
            nameInspectorValid: false,
            inspectionDateValid: true,
            confirmationTextValid: false,
            confirmationValid: false,
            formValid: false,
            formMaterialValid: false,
            setPlaceHolderValue: false,
            formErrors: {
                supplier: '',
                orderNumber: '',
                deliveryNoteNumber: '',
                deliveryNumber: '',
                orderPosition: '',
                materialNumber: '',
                materialDescription: '',
                quantity: '',
                serialNumber: '',
                overviewTests: '',
                nameInspector: '',
                inspectionDate: '',
                confirmationText: '',
                confirmation: ''
            },
            deliveryNoteNumber: '',
            defaultDeliveryNoteNumber: '',
            orderNumbersForSupplier: [],
            materials: [],
            documentProfiles: [],
            orderPositionSelected: '',
            supplierNumber: '',
            decryptError: false,
            selectedFiles: {},
            isLoadingPrefix: false,
            apiErrorMessage: '',
            apiErrorMessageForDevelopers: '',
        };

    }

    setSupplierNumber() {
        const IAM_API = certblockAPI.getIAM_API(this.getUser().id_token);
        IAM_API.userCurrent()
        .then((certblockUser) => {
            this.setState({
                // testerList: certblockUser.data.testers ? certblockUser.data.testers : [],
                // tests: certblockUser.data.materialTests ? certblockUser.data.materialTests : [],
                supplierNumber: certblockUser.data.supplier ? certblockUser.data.supplier : ''
            });
        }).catch((error) => {
            this.setState({
                showAPIErrorModal: true,
                showLoadingModal: false,
                showSavingModal: false,
                apiErrorMessage: globalMessages[localStorage.getItem('language')].createCertificate.uiErrorMessageFailAPICall
            });
        });

    }

    
    componentDidMount() {
        this.getRole();
        this.setSupplierNumber();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.supplierNumber !== '' && this.state.supplierNumber !== prevState.supplierNumber) {
            this.getOrderNumbers(this.state.supplierNumber);
        }
    }    

    getDirectLinkCertificate = () => {


        const oNr = sessionStorage.getItem("orderNumber");
        // console.log(`DEBUG: [orderNumberFromStaticLink] ${oNr}`)
        const oPos = sessionStorage.getItem("orderPosition");
        const dBr = sessionStorage.getItem("deliveryNumber");
        if (oNr && oPos && dBr) {
            this.setState({
                showLoadingModal: true
            });
          
            const CRT_API = certblockAPI.getCRT_API(this.getUser().id_token);
            CRT_API.getCertificateId(oNr, oPos, dBr)
                .then((certificate) => {
                    this.setState({
                        showLoadingModal: false
                    });

                    sessionStorage.removeItem("orderNumber");
                    sessionStorage.removeItem("orderPosition");
                    sessionStorage.removeItem("deliveryNumber");

                    this.showCertificate(certificate.data, 0);
                })
                .catch(() => {
                    this.setState({
                        showLoadingModal: false
                    });

                    sessionStorage.removeItem("orderNumber");
                    sessionStorage.removeItem("orderPosition");
                    sessionStorage.removeItem("deliveryNumber");
                    console.error('Certificate not found');
                });
        }

    };

    getRole = () => {
        const oidc = new OidcProvider();
        const user = oidc.getUser();

        if (user.role === 'userSupplier') {
            this.setState({ isSupplier: true });
        }

    }

    handleKeyUp = (e) => {
        if (e == null || e.target.name !== "orderNumber") return;

        switch (e.key) {
            case "Enter":
            case "Return": {
                this.getCertificates();
                break;
            }
        }
    };


    handleChange = (e) => {
        let name;
        let value;

        if (e != null) {

            name = e._isAMomentObject ? "inspectionDate" : e.target.name;
            value = e._isAMomentObject ? e : (e.target.type === "checkbox" ? e.target.checked : e.target.value);

            switch (name) {
                case "orderNumber": {
                    this.setState({ orderNumber: value });
                    break;
                }
                case "deliveryNoteNumber": {
                    this.setState({ deliveryNoteNumber: value},()=>{
                        // console.log('defaultDeliveryNoteNumber:' + this.state.defaultDeliveryNoteNumber);
                        // console.log('deliveryNoteNumber:' + this.state.deliveryNoteNumber);
                    });
                    break;
                }
                case "setPlaceHolderValue": {
                    let today = new Date();
                    let myDate =  today.getFullYear() 
                                    + ('0' + (today.getMonth()+1)).slice(-2) 
                                    + ('0' + today.getDate()).slice(-2);
                    this.setState({setPlaceHolderValue: value},()=>{
                        if(this.state.setPlaceHolderValue) {
                            this.setState({
                                defaultDeliveryNoteNumber: myDate
                            },()=>{
                                // console.log('defaultDeliveryNoteNumber:' + this.state.defaultDeliveryNoteNumber);
                                // console.log('deliveryNoteNumber:' + this.state.deliveryNoteNumber);
                            })
                        } else {
                            this.setState({
                                defaultDeliveryNoteNumber: ''
                            },()=>{
                                // console.log('defaultDeliveryNoteNumber:' + this.state.defaultDeliveryNoteNumber);
                                // console.log('deliveryNoteNumber:' + this.state.deliveryNoteNumber);
                            })
                        }
                    });
                    break;
                }
                default: {
                    break;
                }
            }
        }
    };

 

    handleClose = () => {
        this.setState({ showModal: false });
    };

    getUser = () => {
        return JSON.parse(localStorage.getItem('oidc_user'));
    };

    getCertificates = () => {
        this.setState({
            showLoadingModal: true
        });

        const CRT_API = certblockAPI.getCRT_API(this.getUser().id_token);

        CRT_API.getCertificateIdList(this.state.orderNumber)
        .then((certificates) => {
            console.log(`[certificateGet] ${JSON.stringify(certificates.data)}`);
            this.setState({
                certificateList: certificates.data,
                showLoadingModal: false
            });
        })
        .catch((error) => {
            console.error(error);
            this.setState({
                showLoadingModal: false
            });
        });

    };

    showCertificate = (id, version) => {

        this.setState({
            showLoadingModal: true
        });

        // console.log(`DEBUG: [id/version] ${id}/${version}`);
        const CRT_API = certblockAPI.getCRT_API(this.getUser().id_token);

        CRT_API.getCertificateSiemensById(id, version)
        .then((certificate) => {
            this.setState({
                showLoadingModal: false,
                showModal: true,
                supplierData: certificate.data.creator,
                orderNumber: certificate.data.orderNumber,
                deliveryNumber: certificate.data.deliveryNumber,
                materialList: certificate.data.materialList,
                nameInspector: certificate.data.nameInspector,
                inspectionDate: certificate.data.inspectionDate,
                confirmationText: certificate.data.confirmationText,
                confirmation: certificate.data.checkConfirmation,
                checkTestsResults: certificate.data.checkTestsResults,
                consigneeData: certificate.data.consignee,
                orderPosition: certificate.data.materialList[0].orderPosition
            });
        })
        .catch((error) => {
            console.error(error);
            this.setState({
                showLoadingModal: false
            });
        });
    
    };

handleFileChange = (event) => {
    console.log('DEBUG[handleFileChange]');
    let currentFiles = this.state.selectedFiles;
    let position = event.target.name;
    currentFiles[position] = event.target.files[0];
    this.setState({selectedFiles: currentFiles},()=>{
        // console.log(this.state.selectedFiles);
    });
}

errorClass = (error) => {
    return (error.length === 0 ? '' : 'is-invalid');
};


handleChangeOrderNumber = (value) => {
    console.log(`DEBUG  [handleChangeOrderNumber] value: ${value}`);
    this.getMaterials(value,(returnedMaterials)=>{
        this.setState({
            orderNumber: value,
            materials: returnedMaterials,
            selectedFiles: {},
            orderPositionSelected: '',
        },()=>{
            // console.log(this.state.materials);
        });
    })
}   


updateProfilesStatus = async (order, deliveryNoteNumber, orderPosition,) => {
    console.log(`DEBUG  [updateProfilesStatus] order:${order} orderPosition:${orderPosition} deliveryNoteNumber: ${deliveryNoteNumber}`);
    let documentProfiles = this.state.documentProfiles;
    let savedProfiles = await certblockAPI.callApi(
        'getDocuments',
        [order, deliveryNoteNumber, orderPosition],
        'get'
    );
    // console.log(documentProfiles);
    let modifiedProfiles = documentProfiles.map(profile => {
        // console.log(`DEBUG  [updateProfilesStatus] savedProfiles:${JSON.stringify(savedProfiles.data)}`);
        return {
            profileNumber: profile.profileNumber,
            profileAlreadySaved: savedProfiles.data.includes(profile.profileNumber),
            name: profile.name
        }
    });
    // console.log(modifiedProfiles);
    this.setState({
        documentProfiles: modifiedProfiles
    });
}


getProfileNames = async (profiles, order, orderPosition, language) => {
    console.log(`DEBUG  [getProfileNames]`);
    let deliveryNoteNumber = this.state.setPlaceHolderValue? this.state.defaultDeliveryNoteNumber : this.state.deliveryNoteNumber;
    let profileNames = {};
    try {
        await Promise.all(profiles.map(async profile => {
            let profileName = await certblockAPI.callApi(
                'getProfileName',
                [order, deliveryNoteNumber, orderPosition, profile.profileNumber,language],
                'get',
            );
            profileNames[profile.profileNumber] = profileName.data;
            }));
        // console.log(profileNames);
        let newProfiles = profiles.map((profile)=>{
            let newProfile = profile;
            newProfile.name = profileNames[profile.profileNumber].slice(0,MAX_PROFILE_NAME_LENGTH);
            return newProfile;
        });
        this.setState({
            docProfiles: newProfiles
        })
    } catch(error){
        console.log('ERROR: ' + JSON.stringify(error));
        this.setState({
            showAPIErrorModal: true,
            showLoadingModal: false,
            showSavingModal: false,
            apiErrorMessage: globalMessages[localStorage.getItem('language')].searchDocuments.apiErrorMessageGetDocumentProfileName,
            apiErrorMessageForDevelopers: JSON.stringify(error) 
        });
    }
}

handleGetDocumentProfiles = async (order, orderPosition) => {
    console.log(`DEBUG  [handleGetDocumentProfiles] order:${order} orderPosition:${orderPosition}`);
    console.log(`DEBUG  [handleGetDocumentProfiles] deliveryNoteNumber:${this.state.deliveryNoteNumber}`);
    if (this.state.orderPositionSelected == orderPosition) { //Closing the '+' sign
        this.setState({
            documentProfiles: [],
            orderPositionSelected: '',
            selectedFiles: {},
        });
        return;
    }
    this.setState({
        showLoadingModal: true
    });
    let docProfiles;
    await this.getDocumentProfiles(order, orderPosition, (profiles)=>{
        docProfiles = profiles;
        // console.log(docProfiles);
        this.setState({
            documentProfiles: profiles,
            orderPositionSelected: orderPosition,
            selectedFiles: {}, 
        },()=>{
            // console.log(this.state.documentProfiles);
        });
    });
    await this.getProfileNames(docProfiles, order, orderPosition, localStorage.getItem('language').slice(0,2));
    // console.log(`dflt: ${this.state.defaultDeliveryNoteNumber} set:${this.state.deliveryNoteNumber} this.state.setPlaceHolderValue: ${this.state.setPlaceHolderValue}`);

    let deliveryNoteNumber = this.state.setPlaceHolderValue? this.state.defaultDeliveryNoteNumber : this.state.deliveryNoteNumber; 
    //TODO MARWAL testing if change deliveryNoteNumber to '' getting correct results
    await this.updateProfilesStatus(order, deliveryNoteNumber, orderPosition);

    this.setState({
        showLoadingModal: false
    });
}

    async getDocumentProfiles(orderNumber,orderPosition, callback){
        console.log(`DEBUG [getDocumentProfiles] orderNumber: ${orderNumber} orderPosition:${orderPosition}`);
        // const CRT_API = certblockAPI.getCRT_API(this.getUser().id_token);
        let deliveryNoteNumber = this.state.setPlaceHolderValue? this.state.defaultDeliveryNoteNumber : this.state.deliveryNoteNumber;
        try {
            let response = await certblockAPI.getDocumentProfilesAll(orderNumber, deliveryNoteNumber, orderPosition,  callback);
            return response;

        }catch(error){
            console.log('ERROR: ' + JSON.stringify(error));
            this.setState({
                showAPIErrorModal: true,
                showLoadingModal: false,
                showSavingModal: false,
                apiErrorMessage: globalMessages[localStorage.getItem('language')].searchDocuments.apiErrorMessageGetDocumentProfiles,
                apiErrorMessageForDevelopers: JSON.stringify(error) 
            });
        }
    }

    async getMaterials(value, callback) {
        console.log('DEBUG [getMaterials]')
        // const CRT_API = certblockAPI.getCRT_API(this.getUser().id_token);
        try {
            let response = await certblockAPI.getMaterials(value,callback);
            return response;
        } catch(error) {
            console.log('ERROR: ' + JSON.stringify(error));
            // this.setState({
            //     showAPIErrorModal: true,
            //     apiErrorMessage: globalMessages[localStorage.getItem('language')].searchDocuments.apiErrorMessageGetMaterials,
            //     apiErrorMessageForDevelopers: JSON.stringify(error) 
            // });
        }
    }

    async setUploadSlot(orderNumber, orderPosition, profileId){
        console.log(`DEBUG[setUploadSlot] (orderNumber, orderPosition, profileId): (${orderNumber}, ${orderPosition}, ${profileId})`);
        let deliveryNoteNumber = this.state.setPlaceHolderValue? this.state.defaultDeliveryNoteNumber : this.state.deliveryNoteNumber;
            try {
                let request = await certblockAPI.callApi(
                    'setUploadSlots',
                    [orderNumber, deliveryNoteNumber, orderPosition],
                    'post',
                    {'profileId' : profileId},
                )
                return request;
            }catch(error){
                console.log('DEBUG[setUploadSlot] Error:');
                console.log(error.message);
                this.setState({
                    showAPIErrorModal: true,
                    showLoadingModal: false,
                    showSavingModal: false,
                    apiErrorMessage: globalMessages[localStorage.getItem('language')].searchDocuments.apiErrorMessageSetUploadSlots,
                    apiErrorMessageForDevelopers: JSON.stringify(error) 
                });                
        }
    }

    async getUploadSlots(orderNumber, orderPosition, profiles )
    {
        console.log('DEBUG[getUploadSlots]');
        let slots = {};
        try {
            await Promise.all(profiles.map(async profileId => {
                let slot = await this.setUploadSlot(orderNumber, orderPosition, profileId);
                slots[profileId]=slot.data;
            }));
        } catch(error){
            console.log('DEBUG[getUploadSlots] ERROR:');
            console.log(error)
        }
        return slots;
    }

    async saveFilesToSlots(deliveryNoteNumber,slots){
        console.log('DEBUG[saveFilesToSlots]');
        for (const [key, value] of Object.entries(this.state.selectedFiles)) {
            if (this.state.selectedFiles[key] && slots[key].presignedUrl) {
                
                let response = await certblockAPI.callApi(
                    'uploadFile',
                    [deliveryNoteNumber],
                    'put',
                    '',
                    {},
                    slots[key].presignedUrl,
                    value
                );
                slots[key].uploadResuls = response;
            } else {
                // console.log(`slot: ${slots[key].presignedUrl} is missing for file: ${this.state.selectedFiles[key]}`);
            }
        }        
    }

    async myWait(milisec) {
        return new Promise((resolve, reject) => {
            setTimeout(() => { resolve("B"); console.log('waited:' + milisec)}, milisec)
        });
    }

    getSlotsForAnotherPoll (slots) {
        console.log(`DEBUG[getSlotsForAnotherPoll]`);
        let notAssigned = {};
        for (const [key, value] of Object.entries(slots)) {
            if ((slots[key].pollingResultStatus == apiFlagREADY_FOR_UPLOAD) || (
                slots[key].assignResult != apiFlagASSIGN_UPLOAD_COMPLETE
                )){
                notAssigned[key] = slots[key];
            }
        }   
        // console.log(notAssigned); 
        return notAssigned;
    }    

    async poll(orderNumber, deliveryNoteNumber, orderPosition, slots){
        console.log('DEBUG[poll]');
        for (const [key, value] of Object.entries(this.state.selectedFiles)) {
            // console.log('DEBUG[saveDocumentsProfiles] polling..');
            if (this.state.selectedFiles[key] && slots[key].presignedUrl) {
                let response = await certblockAPI.callApi(
                    'pollingFiles',
                    [orderNumber, deliveryNoteNumber, orderPosition, slots[key].slotId],
                    'get'
                );
                slots[key].pollingResult =  response;
                slots[key].pollingResultStatus = response.data.status;
            } 
        } 
    }

    async assignFiles(orderNumber, deliveryNoteNumber, orderPosition, slots){
        console.log('DEBUG[assignFiles]');
        for (const [key, value] of Object.entries(this.state.selectedFiles)) {
            if (this.state.selectedFiles[key] && slots[key].presignedUrl && slots[key].pollingResultStatus == apiFlagFINISHED_PROCESSING) {
                let response = await certblockAPI.callApi(
                    'profileDocsAssignment',
                    [orderNumber, deliveryNoteNumber, orderPosition, key],
                    'put',
                    {'slotId': slots[key].slotId}
                );
                slots[key].assignResponse = response;
                slots[key].assignResult = response.data.message;
            } else {
                slots[key].assignResult = 'NOT_ASSIGNED_YET'
            }
        }
    }
    
    async saveDocumentsProfiles (orderNumber, orderPosition) { 
        console.log('DEBUG[saveDocumentsProfiles]');
        let deliveryNoteNumber = this.state.setPlaceHolderValue? this.state.defaultDeliveryNoteNumber : this.state.deliveryNoteNumber; 

        // console.log(this.state.selectedFiles);
        this.setState({
            showSavingModal: true
        });
        let profileIds = Object.keys(this.state.selectedFiles);
        //getting files slots
        let slots = await this.getUploadSlots(orderNumber, orderPosition, profileIds);

        //saving files to the slots
        await this.saveFilesToSlots(deliveryNoteNumber,slots);

        let totalWaitedTime = 0;
        // console.log(`profileIds: ${JSON.stringify(profileIds)}`);
        let numberOfSlots = Object.keys(slots).length;
        while(numberOfSlots > 0 && totalWaitedTime < MAX_TIME_WAITED) {
            await this.myWait(2000);
            totalWaitedTime += 2000; 
            await this.poll(orderNumber, deliveryNoteNumber, orderPosition, slots);
            // console.log(`waited: ${totalWaitedTime} numberOfSlots: ${numberOfSlots} profileIds.length: ${profileIds.length}`);
            //assigning
            await this.assignFiles(orderNumber, deliveryNoteNumber, orderPosition, slots);
            slots = this.getSlotsForAnotherPoll(slots);
            numberOfSlots = Object.keys(slots).length;
            // console.log('after assigning');
            // console.log(slots);
        }
    
        await this.updateProfilesStatus(orderNumber, deliveryNoteNumber, orderPosition);
        this.setState({
            showSavingModal: false
        });
    }

    getOrderNumbersPrefix = async (orderNumberPrefix) => {
        console.log(` MARWAL DEBUG[getOrderNumbersPrefix] prefix: ${orderNumberPrefix} supplier: ${this.state.supplierNumber}`);
        // const CRT_API = certblockAPI.getCRT_API(this.getUser().id_token);
        this.setState({isLoadingPrefix: true});
        try {
            let supplierNumber = this.state.supplierNumber;
            if(supplierNumber) {
                this.setState({isLoadingPrefix: false});
                return (await certblockAPI.callApi('orderPrefix', [supplierNumber, orderNumberPrefix])).data;
            }
        } catch (e) {
            console.log('MARWAL DEBUG[getOrderNumbersPrefix] Error:');
            console.log(e);
            this.setState({isLoadingPrefix: false});
            this.setState({
                showAPIErrorModal: true,
                showLoadingModal: false,
                showSavingModal: false,
                apiErrorMessage: globalMessages[localStorage.getItem('language')].searchDocuments.apiErrorMessageGetOrderNumberPrefix,
                apiErrorMessageForDevelopers: JSON.stringify(error)  
            });
        }
        return [];
    }

    getOrderNumbers = async (supplierNumber) => {
        console.log(` MARWAL DEBUG[getOrderNumbers] supplier: ${supplierNumber}`);
        let deliveryNoteNumber = this.state.defaultDeliveryNoteNumber ?? this.state.deliveryNoteNumber; 
        // const CRT_API = certblockAPI.getCRT_API(this.getUser().id_token);
        try {
            let ordersList = (await certblockAPI.callApi('orders', [supplierNumber, deliveryNoteNumber])).data; //
            // let ordersList = (await certblockAPI.callApi('orders', [supplierNumber])).data;
            if(!ordersList) {
                this.setState({
                    showAPIErrorModal: true,
                    showLoadingModal: false,
                    showSavingModal: false,
                    apiErrorMessage: globalMessages[localStorage.getItem('language')].searchDocuments.apiErrorMessageGetOrderNumbers,
                    // apiErrorMessageForDevelopers: JSON.stringify(ordersList) 
                });
                return;
            }

            if (ordersList.length > 0) {
                this.setState({ orderNumbersForSupplier: ordersList });
                // console.log('order(s) found ... create your certificate')
            } else {
                this.setState({ showAPIErrorModal: true, apiErrorMessage: globalMessages[localStorage.getItem('language')].createCertificate.uiErrorMessageFailAPICall });
            }
        } catch (e) {
            this.setState({
                showAPIErrorModal: true,
                showLoadingModal: false,
                showSavingModal: false,
                apiErrorMessage: globalMessages[localStorage.getItem('language')].searchDocuments.apiErrorMessageGetOrderNumbers,
                apiErrorMessageForDevelopers: JSON.stringify(e) 
            });
            return;
        }
    }


    render = () => {

        return (
            <div>
                <NavBar />
                <FormErrors formErrors={this.state.formErrors} />
                {this.state.isSiemensOrSupplier &&
                    <div className="container col-xl-8 col-lg-9 col-md-12 col-sm-12">
                        {/* <div className="container col-xl-9 col-lg-9 col-md-12 col-sm-12"> */}
                        {/* <form onSubmit={this.handleSubmit}>     --> not needed here and would prevent that "handleKeyUp"-event is executed/handled!*/}
                        <h2><FormattedMessage id="uploadDocuments.formTitle" /></h2>

                        <div className="card" style={{ padding: 5, marginTop: 30 }}>
                            <div className="form-row">
                                <div className="form-group col-md-9 col-sm-12">
                                    <label htmlFor="deliveryNoteNumberInput"><FormattedMessage id="uploadDocuments.formDeliveryNoteNumber" /></label>
                                    <input name="deliveryNoteNumber" type="text" 
                                        onChange={this.handleChange} 
                                        onKeyUp={this.handleKeyUp} 
                                        className="form-control" id="deliveryNoteNumberInput" 
                                        placeholder={globalMessages[localStorage.getItem('language')].uploadDocuments.formDeliveryNoteNumberPlaceholder} 
                                        disabled={this.state.setPlaceHolderValue}/>
                                    <div className="invalid-feedback"><FormattedMessage id="showCertificate.formOrderNumber" /> {this.state.formErrors.orderNumber}</div>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group col-md-9 col-sm-12">
                                    <label>
                                        <input name='setPlaceHolderValue' type="checkbox" checked={this.setPlaceHolderValue} onChange={this.handleChange} />
                                        <FormattedMessage id="uploadDocuments.formCheckboxSetPlaceHolderValue" /> 
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div className="card" style={{ padding: 5, marginTop: 30 }}>
                            <div className="form-row">
                                <div className="form-group col-md-9 col-sm-12">
                                    <label htmlFor="orderNumberInput"><FormattedMessage id="uploadDocuments.formOrderNumber" /></label>
                                    <AsyncTypeahead
                                            onChange={this.handleChangeOrderNumber}
                                            id="orderNumberInput"
                                            isLoading={this.state.isLoadingPrefix}
                                            value={this.state.orderNumber}
                                            inputProps={{ className: `form-control ${this.errorClass(this.state.formErrors.orderNumber)}` }}
                                            placeholder={globalMessages[localStorage.getItem('language')].createCertificate.formOrderNumberPlaceHolder}
                                            options={this.state.orderNumbersForSupplier}
                                            onSearch={this.getOrderNumbersPrefix}/>
                                    {/* <input name="orderNumber" type="text" onChange={this.handleChange} onKeyUp={this.handleKeyUp} className="form-control" id="orderNumberInput" placeholder={globalMessages[localStorage.getItem('language')].uploadDocuments.formOrderNumberPlaceHolder} /> */}
                                    <div className="invalid-feedback"><FormattedMessage id="uploadDocuments.formOrderNumber" /> {this.state.formErrors.orderNumber}</div>
                                </div>
                                {/* <div className="form-group col-md-3 col-sm-12 d-flex justify-content-center" style={{ marginTop: 31 }}>
                                    <button type="button" onClick={this.getCertificates} className="btn btn-primary" style={{ whiteSpace: 'normal' }}><FormattedMessage id="uploadDocuments.formShowCertificatesBtn" /></button>
                                </div> */}
                            </div>
                        </div>

                        <div className="card" style={{padding: 5, marginTop: 30}} >
                            <div id="normalMode">
                                <label htmlFor="orderNumberInput"><FormattedMessage id="createCertificate.formMaterialFromOrderNum" /></label>                                
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th scope="col"><FormattedMessage id="uploadDocuments.formOrderPosition" /></th>
                                            <th scope="col"><FormattedMessage id="uploadDocuments.formQuantity" /></th>
                                            <th scope="col"><FormattedMessage id="uploadDocuments.formMaterialNumber" /></th>
                                            <th scope="col"><FormattedMessage id="uploadDocuments.formMaterialName" /></th>
                                            <th scope="col"><FormattedMessage id="uploadDocuments.formProfiles" /></th>
                                            <th scope="col"><FormattedMessage id="uploadDocuments.formAttachments" /></th>
                                            <th scope="col"><FormattedMessage id="uploadDocuments.formName" /></th>
                                            <th scope="col"><FormattedMessage id="uploadDocuments.status" /></th>
                                            <th scope="col" />
                                        </tr>
                                    </thead>  
                                    <tbody>
                                        {this.state.materials.sort((a,b)=>{
                                            if(a.orderPosition < b.orderPosition) {return -1}
                                            return 1
                                        }).map((material, index) =>{
                                            return (
                                                <tr key={material.orderPosition} className="pdfItemMaterialList">
                                                    <td>{material.orderPosition}</td>
                                                    <td>{material.quantity}</td>
                                                    <td>{material.materialNumber}</td>
                                                    <td>{material.materialDescription}</td>
                                                    <td>
                                                        <button type="button" value={index} onClick={()=>{this.handleGetDocumentProfiles(this.state.orderNumber, material.orderPosition)}} id={'addButton' + index} className="btn btn-primary btnDeleteMaterial" disabled={false} style={{ padding: 0 }}>
                                                            <MdAdd size={25} />
                                                            {/* <FormattedMessage id="uploadDocuments.showDocsButton" /> */}
                                                        </button>
                                                    </td>
                                                    <td>
                                                        {this.state.orderPositionSelected == material.orderPosition  
                                                        && this.state.documentProfiles.map((profile, idx)=>{
                                                            return (     
                                                                <form key={idx}>
                                                                        <label className="btn btn-primary" htmlFor={profile.profileNumber} >{profile.profileNumber}<MdAttachFile size="1.25em"/>
                                                                            <input id={profile.profileNumber} type="file" name={profile.profileNumber} onChange={this.handleFileChange} style={{display: 'none'}}/>
                                                                        </label>    
                                                                </form> 
                                                            )   
                                                        })
                                                        }
                                                    </td>
                                                    <td>
                                                        {this.state.orderPositionSelected == material.orderPosition  
                                                        && this.state.documentProfiles.map((profile, idx)=>{
                                                            return (     
                                                                <form key={idx+'name'}>
                                                                <label className="btn"  >
                                                                    {/* <FormattedMessage id="uploadDocuments.formName" /> */}
                                                                    {profile.name}
                                                                </label>    
                                                            </form>     
                                                            )   
                                                        })
                                                        }
                                                    </td>
                                                    <td>
                                                    {this.state.orderPositionSelected == material.orderPosition  
                                                        && this.state.documentProfiles.map((profile, idx)=>{
                                                            return (     
                                                                <form key={idx}>
                                                                    <label className="btn" disabled={true} >
                                                                        <MdCheckBox  hidden={!profile.profileAlreadySaved} size="1.25em"/>
                                                                        <MdCheckBoxOutlineBlank  hidden={profile.profileAlreadySaved} size="1.25em"/>
                                                                    </label>    
                                                                </form>
                                                            )   
                                                        })
                                                        }
                                                    </td>
                                                    <td>
                                                        <button type="button" value={index} 
                                                            onClick={()=>this.saveDocumentsProfiles(this.state.orderNumber, material.orderPosition)} 
                                                            id={'saveButton' + index} className="btn btn-success" 
                                                            hidden={this.state.orderPositionSelected != material.orderPosition} 
                                                            style={{ padding: 0 }} 
                                                            disabled={this.state.deliveryNoteNumber == '' && this.state.defaultDeliveryNoteNumber == ''}><FormattedMessage id="uploadDocuments.saveButton" />
                                                        </button>
                                                    </td>
                                                </tr> 
                                            )
                                            
                                        })}
                                    </tbody>                                  
                                </table>
                            </div>
                        </div>
                    </div>
                }
                <br/>
                <div>
                    <LoadingComponent hidden={this.state.showLoadingModal} />
                </div> 
                <div>
                    <SavingComponent hidden={this.state.showSavingModal} />
                </div> 
                <div>
                    <Modal animation={false} show={this.state.showAPIErrorModal}>
                        <ModalHeader>
                            <Modal.Title><FormattedMessage id="uploadDocuments.uiErrorMessageFailTitleAPICall" /></Modal.Title>
                        </ModalHeader>    
                        <Modal.Body>
                            <div>{this.state.apiErrorMessage}</div>
                            <div>{this.state.apiErrorMessageForDevelopers}</div>
                        </Modal.Body>    
                        <Modal.Footer>
                            <Button 
                            onClick={()=>{this.setState({showAPIErrorModal: false})}}><FormattedMessage id="uploadDocuments.closeButton" /></Button>
                        </Modal.Footer>                
                    </Modal>
                </div>         
                <Footer />
            </div >
        );
    }
}

export default UploadDocumentsContainer
