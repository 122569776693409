import React from 'react';
import FormErrors from '../components/FormErrors';
import { Modal, Button, ModalHeader } from 'react-bootstrap';
import LoadingComponent from '../components/LoadingComponent';
import SavingComponent from '../components/SavingComponent';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import { FormattedMessage } from 'react-intl';
import Messages from '../messages';
import {MdAdd, MdAttachFile, MdEdit, MdCheckBox, MdCheckBoxOutlineBlank, MdCloudDownload} from 'react-icons/lib/md';
import {AsyncTypeahead} from 'react-bootstrap-typeahead';
import PDFViewer from '../containers/PdfViewer';
import {blobToDataURL} from 'blob-util';


import 'jspdf-autotable';
import Footer from '../components/Footer';
import OidcProvider from '../services/OidcProvider';
import NavBar from '../components/NavBar';
import { ApiService } from '../services/ApiService';

const globalMessages = Messages;
const apiFlagREADY_FOR_UPLOAD = 'READY_FOR_UPLOAD';
const apiFlagFINISHED_PROCESSING = 'FINISHED_PROCESSING ';
const apiFlagERROR_PROCESSING = 'ERROR_PROCESSING';
const apiFlagASSIGN_UPLOAD_COMPLETE = 'Upload complete';
const MAX_TIME_WAITED = 30000;


const certblockAPI = new ApiService();

export class SearchContainer extends React.Component {

    constructor(props) {

        super(props);

        this.apiConfig = window.ApiConfig;
        this.deliveryNumberInput = React.createRef();

        this.state = {
            isSiemensOrSupplier: true,
            certificateId: '',
            supplier: '',
            orderNumber: '',
            deliveryNumber: '',
            orderPosition: '',
            materialNumber: '',
            materialDescription: '',
            quantity: '',
            serialNumber: '',
            overviewTests: '',
            nameInspector: '',
            inspectionDate: moment(),
            confirmationText: '',
            confirmation: '',
            checkTestsResults: '',
            materialList: [],
            certificateList: [],
            supplierData: {
                name: '',
                street: '',
                zip: '',
                city: '',
                country: ''
            },
            consigneeIndex: null,
            consigneeData: {
                name: '',
                street: '',
                zip: '',
                city: '',
                country: ''
            },
            expandedRows: [],
            showModal: false,
            showLoadingModal: false,
            showSavingModal: false,
            supplierValid: true,
            orderNumberValid: false,
            deliveryNumberValid: false,
            orderPositionValid: true,
            materialNumberValid: false,
            materialDescriptionValid: false,
            quantityValid: false,
            serialNumberValid: true,
            overviewTestsValid: true,
            nameInspectorValid: false,
            inspectionDateValid: true,
            confirmationTextValid: false,
            confirmationValid: false,
            formValid: false,
            formMaterialValid: false,
            setPlaceHolderValue: false,
            formErrors: {
                supplier: '',
                orderNumber: '',
                deliveryNoteNumber: '',
                deliveryNumber: '',
                orderPosition: '',
                materialNumber: '',
                materialDescription: '',
                quantity: '',
                serialNumber: '',
                overviewTests: '',
                nameInspector: '',
                inspectionDate: '',
                confirmationText: '',
                confirmation: ''
            },
            deliveryNoteNumber: '',
            deliveryNoteNumbers: [],
            orderNumbersForSupplier: [],
            materials: [],
            documentProfiles: {},
            orderPositionSelected: '',
            supplierNumber: '',
            decryptError: false,
            selectedFiles: {},
            isLoadingPrefix: false,
            editedDNNforOrderPosition: null,
            urlToPdf: null,
            loadedPdfOrderPosition: '',
            loadedPdfProfileId: '',
            isSupplier: false,
            editableDNN: '',
            savedDNN: '',
            apiErrorMessage: '',
            apiErrorMessageForDevelopers: '',
            orderPositions: []
        };

    }

    setSupplierNumber() {
        const IAM_API = certblockAPI.getIAM_API(this.getUser().id_token);
        IAM_API.userCurrent()
        .then((certblockUser) => {
            this.setState({
                // testerList: certblockUser.data.testers ? certblockUser.data.testers : [],
                // tests: certblockUser.data.materialTests ? certblockUser.data.materialTests : [],
                supplierNumber: certblockUser.data.supplier ? certblockUser.data.supplier : ''
            });
        }).catch((error) => {
            this.setState({
                showAPIErrorModal: true,
                apiErrorMessage: globalMessages[localStorage.getItem('language')].createCertificate.uiErrorMessageFailAPICall,
                apiErrorMessageForDevelopers: JSON.stringify(error)
            });
        });

    }

    

    componentDidMount() {

        this.getRole();
        this.setSupplierNumber();
        // console.log(`DEBUG [componentDidMount] this.state.serialNumber: ${this.state.serialNumber}`);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.supplierNumber !== '' && this.state.supplierNumber !== prevState.supplierNumber) {
            this.getOrderNumbers(this.state.supplierNumber);
        }
    }    


    getRole = () => {
        console.log(`Debug[getRole]`);
        const oidc = new OidcProvider();
        const user = oidc.getUser();
        
        // console.log(user);

        if (user.role === 'userSupplier') {
            this.setState({ isSupplier: true },()=>{
                // console.log(`Debug[getRole] isSupplier: ${this.state.isSupplier}`);
            });
            //MARWAL @TODO MOCK change to Siemens => works with tkn out => to be tested
            // this.setState({ isSupplier: false },()=>{
            //     console.log(`Debug[getRole] isSupplier: ${this.state.isSupplier}`);
            // });
        }

    }

    handleKeyUp = (e) => {
        if (e == null || e.target.name !== "orderNumber") return;

        switch (e.key) {
            case "Enter":
            case "Return": {
                this.getCertificates();
                break;
            }
        }
    };


    handleChange = (e) => {
        let name;
        let value;

        if (e != null) {

            name = e._isAMomentObject ? "inspectionDate" : e.target.name;
            value = e._isAMomentObject ? e : (e.target.type === "checkbox" ? e.target.checked : e.target.value);

            switch (name) {
                case "orderNumberInput": {
                    this.setState({ orderNumber: value },()=>{
                        if(this.state.orderNumber.toString().length >= 10) {
                            this.handleChangeOrderNumber(this.state.orderNumber);
                        } else {
                            this.setState({
                                deliveryNoteNumbers: [],
                                materials: [],
                                documentProfiles: {},
                                orderPositions: [],
                            });
                        }
                    });
                    break;
                }
                case "deliveryNoteNumber": {
                    this.setState({ deliveryNoteNumber: value},()=>{
                        // console.log('deliveryNoteNumber:' + this.state.deliveryNoteNumber);
                    });
                    break;
                }
                default: {
                    break;
                }
            }
        }
    };

    handleClose = () => {
        this.setState({ showModal: false });
    };

    getUser = () => {
        return JSON.parse(localStorage.getItem('oidc_user'));
    };

    getCertificates = () => {
        this.setState({
            showLoadingModal: true
        });

        const CRT_API = certblockAPI.getCRT_API(this.getUser().id_token);

        CRT_API.getCertificateIdList(this.state.orderNumber)
        .then((certificates) => {
            // console.log(`[certificateGet] ${JSON.stringify(certificates.data)}`);
            this.setState({
                certificateList: certificates.data,
                showLoadingModal: false
            });
        })
        .catch((error) => {
            console.error(error);
            this.setState({
                showLoadingModal: false
            });
        });

    };

handleFileChange = (event,order,profile) => {
    console.log(`DEBUG[handleFileChange] order: ${order} profile: ${profile}`);

    let file = event.target.files[0];
    let orderPosition = order;
    let profileNumber = profile;


    let selectedNewFiles = this.state.selectedFiles;

    let currentFilesForOrderPosition = selectedNewFiles[orderPosition] == undefined? [] : selectedNewFiles[orderPosition];
    currentFilesForOrderPosition.push({
        profileNumber: profileNumber,
        file: file
    });

    selectedNewFiles[orderPosition] = currentFilesForOrderPosition;
    this.setState({selectedFiles: selectedNewFiles},()=>{
        // console.log(this.state.selectedFiles);
    });

}

downloadFile = async (fileUrl,orderNumber,deliveryNoteNumber,orderPosition,profileId) => {
    console.log(`DEBUG[downloadFile] order: ${orderPosition} profile: ${profileId} fileUrl: ${fileUrl}`);
    this.setState({
        showLoadingModal: true
    });
    const response = await fetch(fileUrl)
    if (!response.ok) {
        this.setState({
            showLoadingModal: false,
            showAPIErrorModal: true,
            apiErrorMessage: globalMessages[localStorage.getItem('language')].searchDocuments.apiErrorMessageDownload,
            apiErrorMessageForDevelopers: response.statusText + ' @ ' + response.url
        });
        console.log('error');
        return
    }
    const blob = await response.blob();
    const dataUrl = await blobToDataURL(blob);
  
    const downloadLink = document.createElement('a');
    downloadLink.href = dataUrl;
    downloadLink.download = orderNumber + '_' + 
                 deliveryNoteNumber + '_' + 
                 orderPosition + '_' + 
                 profileId + '.pdf'; 
    downloadLink.click(); 
    this.setState({
        showLoadingModal: false
    });
}

handleFileDownload = async (event,orderPosition,profileId) => {
    console.log(`DEBUG[handleFileDownload] order: ${orderPosition} profile: ${profileId}`);
    console.log(event.target);
    console.log(this.state.savedDNN);
    console.log(this.state.deliveryNoteNumber);
    // let deliveryNoteNumber = this.state.deliveryNoteNumber;
    let deliveryNoteNumber = this.state.savedDNN == ''? this.state.deliveryNoteNumber : this.state.savedDNN;
    let orderNumber = this.state.orderNumber;
    
    this.setState({
        showLoadingModal: true,
        loadedPdfOrderPosition: orderPosition,
        loadedPdfProfileId: profileId
    });
    let docuFile;
    try {
        docuFile = await certblockAPI.callApi(
            'documentProfile',
            [orderNumber, deliveryNoteNumber, orderPosition, profileId],
            'get'
        );
    } catch (error) {
        console.log('ERROR: ' + JSON.stringify(error));
        this.setState({
            showAPIErrorModal: true,
            showLoadingModal: false,
            showSavingModal: false,
            apiErrorMessage: globalMessages[localStorage.getItem('language')].searchDocuments.apiErrorMessageDownload,
            apiErrorMessageForDevelopers: JSON.stringify(error)            
        });
    }

    this.setState({
        showLoadingModal: false,
    });

    if (docuFile && docuFile.data && docuFile.data.downloadUrl){
        await this.downloadFile(
            docuFile.data.downloadUrl,
            orderNumber,
            deliveryNoteNumber,
            orderPosition,
            profileId);

        this.setState({
            urlToPdf: docuFile.data.downloadUrl
        },()=>{
            // window.location.href = this.state.urlToPdf;


            // console.log(this.state.urlToPdf);
            // let element = document.getElementById('pdfDisplay');
            // if (element) {
            //     element.scrollIntoView({ behavior: 'smooth' });
            // };
            // window.open(docuFile.data.downloadUrl,'resizable,scrollbars');
            // window.open(docuFile.data.downloadUrl, "_blank", "noreferrer");
            // window.open(docuFile.data.downloadUrl);
            // window.open( docuFile.data.downloadUrl, '_blank', 'fullscreen=yes')
        });         
    }
}   

errorClass = (error) => {
    return (error.length === 0 ? '' : 'is-invalid');
};



updateProfilesStatus = async (orderNumber, deliveryNoteNumber, orderPosition) => {
    console.log(`DEBUG  [updateProfilesStatus] order:${orderNumber} deliveryNoteNumber: ${deliveryNoteNumber} orderPosition:${orderPosition}`);
    let documentProfilesWithNoStatus = this.state.documentProfiles;
    let docsForPosition = this.state.documentProfiles[orderPosition];
    let docsForPositionWithStatus;

    // console.log(`${orderNumber}, ${deliveryNoteNumber}, ${orderPosition}`);
    let savedProfiles = await certblockAPI.callApi(
        'getDocuments',
        [orderNumber, deliveryNoteNumber, orderPosition],
        'get'
    );

    docsForPositionWithStatus = docsForPosition.map(profile => {
        return {
            profileNumber: profile.profileNumber,
            orderPosition: profile.orderPosition,
            profileAlreadySaved: savedProfiles.data.includes(profile.profileNumber)
        }
    });


    documentProfilesWithNoStatus[orderPosition] = docsForPositionWithStatus;
    this.setState({
        documentProfiles: documentProfilesWithNoStatus
    },()=>{
        // console.log(this.state.documentProfiles);
        // let uplodedDocuments = {}
        // for (const [key, value] of Object.entries(this.state.documentProfiles)) {
        //     let savedDocs =  value.reduce((ac,el)=> el.profileAlreadySaved? ac+1 : ac, 0);
        //     uplodedDocuments[key]=savedDocs;
        // }
    })
}


handleChangeOrderNumber = (orderNumber) => {
    console.log(`DEBUG  [handleChangeOrderNumber] value: ${orderNumber}`);
    let lengthOfOrderField = orderNumber.toString().length;
    // console.log(`DEBUG  [handleChangeOrderNumber] lengthOfOrderField: ${lengthOfOrderField}`);
    if (lengthOfOrderField >= 10) {
        this.getDeliveryNotes(orderNumber,(returnedDeliveryNotes)=> {
            this.setState({
                deliveryNoteNumbers:returnedDeliveryNotes,
                orderNumber: orderNumber,
                materials: [],
            })
        });
    }
}   

handleChangeNewDeliveryNoteNumber = (event, orderPosition) => {
    console.log(`DEBUG  [handleChangeNewDeliveryNoteNumber] orderPosition: ${orderPosition}`);
    let value;
    value = event.target.value;
    // console.log(`field value: ${value}`);
    this.setState({
        editableDNN: value
    })
}

handleShowDocuments = async (orderNumer, deliveryNoteNumber) => {
    console.log(`DEBUG  [handleShowDocuments] orderNumer:${orderNumer} deliveryNoteNumber:${deliveryNoteNumber}`);
    let orderPositions = [];

    this.setState({
        showLoadingModal: true,
        deliveryNoteNumber: deliveryNoteNumber,
        savedDNN: '',
    });

    await this.getMaterials(orderNumer, (returnedMaterials) => {
        this.setState({
            materials: returnedMaterials,
        })
    });

    let orderPositionResult = await certblockAPI.callApi(
        'getOrderPositions',
        [orderNumer, deliveryNoteNumber],
        'get'
    );
    orderPositions = (orderPositionResult && orderPositionResult.data) ? orderPositionResult.data : [];
    console.log(`orderPositions ${JSON.stringify(orderPositions)}`);

    let profiles = {}; 
    
    
    //TODO testing below with getOrderPositions call results
   
    
    
    orderPositions.forEach(orderPosition => {
            // this.setState({
            //     showLoadingModal: false
            // });
            this.getDocumentProfiles(orderNumer, deliveryNoteNumber, orderPosition, result =>{
                // console.log(`getDocumentProfiles`);
                // console.log(result);
                profiles[orderPosition] = result;
                this.setState({
                    documentProfiles: profiles,
                    editedDNNforOrderPosition: null
                },()=>{
                    this.updateProfilesStatus(orderNumer, deliveryNoteNumber, orderPosition);
                });
            });
    });

    // await Promise.all(
    //     orderPositions.map(async orderPosition => {
    //         console.log(`orderPosition: ${orderPosition}`);
    //         await this.getDocumentProfiles(orderNumer, deliveryNoteNumber, orderPosition, result =>{
    //             console.log(`result: ${result}`);
    //             profiles[orderPosition] = result;
    //             this.setState({
    //                 documentProfiles: profiles,
    //                 editedDNNforOrderPosition: null
    //             },()=>{
    //                 this.updateProfilesStatus(orderNumer, deliveryNoteNumber, orderPosition);
    //             });
    //         })
    //     })
    // );



    //TODO MARWAL below change the loop to use onlt orderPositions

    //OLD VERSION :
    // let profiles = {};            
    // this.state.materials.forEach(material => {
    //         this.setState({
    //             showLoadingModal: false
    //         });
    //         let orderPosition = material.orderPosition
    //         this.getDocumentProfiles(orderNumer, deliveryNoteNumber, material.orderPosition, result =>{
    //             // console.log(`getDocumentProfiles`);
    //             // console.log(result);
    //             profiles[orderPosition] = result;
    //             this.setState({
    //                 documentProfiles: profiles,
    //                 editedDNNforOrderPosition: null
    //             },()=>{
    //                 this.updateProfilesStatus(orderNumer, deliveryNoteNumber, orderPosition);
    //             });
    //         });
    // });
    //END OF OLD VERSION

    this.setState({
        showLoadingModal: false,
        editableDNN: deliveryNoteNumber,
        orderPositions: orderPositions
    },()=>{
        // console.log(this.state.editableDNN)
    });
}


    async getDocumentProfiles(orderNumber,deliveryNote, orderPosition, callback){
        console.log(`DEBUG [getDocumentProfiles] orderNumber: ${orderNumber} orderPosition:${orderPosition}`);
        // const CRT_API = certblockAPI.getCRT_API(this.getUser().id_token);
        let deliveryNoteNumber = this.state.deliveryNoteNumber; 
        if(deliveryNote) {
            deliveryNoteNumber = deliveryNote;
        } 
        try {
            let response = await certblockAPI.getDocumentProfiles(orderNumber, deliveryNoteNumber, orderPosition,  callback);
            console.log(response);
            return response;
        }catch(error){
            console.log('ERROR: ' + JSON.stringify(error));
            this.setState({
                showAPIErrorModal: true,
                showLoadingModal: false,
                showSavingModal: false,
                apiErrorMessage: globalMessages[localStorage.getItem('language')].searchDocuments.apiErrorMessageGetDocumentProfiles,
                apiErrorMessageForDevelopers: JSON.stringify(error)                
            });
        }
    }

    async getMaterials(orderNumer, callback) {
        console.log('DEBUG [getMaterials]')
        // const CRT_API = certblockAPI.getCRT_API(this.getUser().id_token);
        try {
            let response = await certblockAPI.getMaterials(orderNumer,callback);
            return response;
        } catch(error) {
            console.log('ERROR: ' + JSON.stringify(error));
            // this.setState({
            //     showAPIErrorModal: true,
            //     apiErrorMessage: globalMessages[localStorage.getItem('language')].searchDocuments.apiErrorMessageGetMaterials,
            //     apiErrorMessageForDevelopers: JSON.stringify(error)                      
            // });
        }
    }

    async getDeliveryNotes(orderNumber,callback) {
        console.log('DEBUG [getDeliveryNotes]');
        try {
            let response = await certblockAPI.getDeliveryNotes(orderNumber, callback);
            return response;
        }catch(error){
            console.log('Error: ' + JSON.stringify(error));
            this.setState({
                showAPIErrorModal: true,
                showLoadingModal: false,
                showSavingModal: false,
                apiErrorMessage: globalMessages[localStorage.getItem('language')].searchDocuments.apiErrorMessageGetDeliveryNotes,
                apiErrorMessageForDevelopers: JSON.stringify(error)                  
            });
        }
    }

    async setUploadSlot(orderNumber, orderPosition, profileId){
        console.log(`DEBUG[setUploadSlot] (orderNumber, orderPosition, profileId): (${orderNumber}, ${orderPosition}, ${profileId})`);
        let deliveryNoteNumber = this.state.deliveryNoteNumber; 
            try {
                let request = await certblockAPI.callApi(
                    'setUploadSlots',
                    [orderNumber, deliveryNoteNumber, orderPosition],
                    'post',
                    {'profileId' : profileId},
                )
                return request;
            }catch(error){
                console.log('DEBUG[setUploadSlot] Error:');
                console.log(error.message);
                this.setState({
                    showAPIErrorModal: true,
                    showLoadingModal: false,
                    showSavingModal: false,
                    apiErrorMessage: globalMessages[localStorage.getItem('language')].searchDocuments.apiErrorMessageSetUploadSlots,
                    apiErrorMessageForDevelopers: JSON.stringify(error)  
                });                
        }
    }

    async getUploadSlots(orderNumber, orderPosition, profiles ){
        console.log('DEBUG[getUploadSlots]');
        let slots = {};
        try {
            await Promise.all(profiles.map(async profileId => {
                let slot = await this.setUploadSlot(orderNumber, orderPosition, profileId);
                slots[profileId]=slot.data;
            }));
        } catch(error){
            console.log('DEBUG[getUploadSlots] ERROR:');
            console.log(error)
        }
        return slots;
    }

    async saveSlots(orderPosition, profileIds, slots){   
        console.log(`DEBUG[saveSlots]`);
        let deliveryNoteNumber = this.state.deliveryNoteNumber; 
        for (const profileId of profileIds) {
            let file;
            // console.log(`DEBUG[saveSlots] saving..${profileId}`);
            let fileObject = this.state.selectedFiles[orderPosition].find((file)=>file.profileNumber == profileId);
            if (fileObject) {
                file = fileObject.file;
            }
            if(file && slots[profileId] && slots[profileId].presignedUrl) {
                let response = await certblockAPI.callApi(
                    'uploadFile',
                    [deliveryNoteNumber],
                    'put',
                    '',
                    {},
                    slots[profileId].presignedUrl,
                    file
                );
                slots[profileId].uploadResult = response;
            }
        }
    }
    
    async poll(orderNumber, orderPosition, profileIds, slots) {
        console.log(`DEBUG[polling]`);
        let deliveryNoteNumber = this.state.deliveryNoteNumber; 
        for (const profileId of profileIds) {
            // console.log(`DEBUG[saveDocumentsProfiles] polling..${profileId}`);
            if (slots[profileId] && slots[profileId].presignedUrl) {
                let response = await certblockAPI.callApi(
                    'pollingFiles',
                    [orderNumber, deliveryNoteNumber, orderPosition, slots[profileId].slotId],
                    'get'
                );
                slots[profileId].pollingResult = response;
                slots[profileId].pollingResultStatus = response.data.status
          } 
        }
    }

    async assignFilesSearch(orderNumber, orderPosition, profileIds, slots){
        console.log(`DEBUG[assignFilesSearch]`);
        let deliveryNoteNumber = this.state.deliveryNoteNumber; 
        for (const profileId of profileIds) {
            // console.log(`DEBUG[saveDocumentsProfiles] assignment..${profileId}`);
            // if (slots[profileId] && slots[profileId].presignedUrl && slots[profileId].pollingResultStatus == apiFlagFINISHED_PROCESSING) {
            if (slots[profileId] && slots[profileId].presignedUrl ) {
                let response = await certblockAPI.callApi(
                    'profileDocsAssignment',
                    [orderNumber, deliveryNoteNumber, orderPosition, profileId],
                    'put',
                    {'slotId': slots[profileId].slotId}
                );
                slots[profileId].assignResponse = response;
                slots[profileId].assignResult = response.data.message;
                // console.log(slots[profileId]);
            } else {
                slots[profileId].assignResult = 'NOT_ASSIGNED_YET'
            }
        }
    }

    getSlotsForAnotherPoll (slots) {
        console.log(`DEBUG[getSlotsForAnotherPoll]`);
        let notAssigned = {};
        for (const [key, value] of Object.entries(slots)) {
            if ((slots[key].pollingResultStatus == apiFlagREADY_FOR_UPLOAD) || (
                slots[key].assignResult != apiFlagASSIGN_UPLOAD_COMPLETE
                )){
                notAssigned[key] = slots[key];
            }
        }   
        // console.log(notAssigned); 
        return notAssigned;
    }

    selectIdsFromSlots (profileIds, slots) {  
        console.log(`DEBUG[selectIdsFromSlots]`);
        let newIDs  = [];
        profileIds.forEach(profileId => {
            if(slots[profileId] != undefined) newIDs.push(profileId);
        });
        return newIDs;
    }

    async myWait(milisec) {
        return new Promise((resolve, reject) => {
            setTimeout(() => { resolve("B"); console.log('waited:' + milisec)}, milisec)
        });
    }

    async saveDocumentsProfiles (orderNumber, orderPosition) { 
        console.log(`DEBUG[saveDocumentsProfiles] orderNumber: ${orderNumber} orderPosition: ${orderPosition} ${this.state.deliveryNoteNumber}`);
        let deliveryNoteNumber = this.state.savedDNN == ''? this.state.deliveryNoteNumber : this.state.savedDNN; 
        if(!this.state.selectedFiles || !this.state.selectedFiles[orderPosition]) {
            console.log('no files to be saved');
            return;
        }
        this.setState({
            showSavingModal: true
        });
        let profileIds = this.state.selectedFiles[orderPosition].map(fileData=>{return fileData.profileNumber});
        //getting files slots
        let slots = await this.getUploadSlots(orderNumber, orderPosition, profileIds);
        //saving files to the slots
        await this.saveSlots(orderPosition, profileIds, slots);

        let totalWaitedTime = 0;
        while(profileIds.length != 0 && totalWaitedTime < MAX_TIME_WAITED ) {
            await this.myWait(2000);
            totalWaitedTime += 2000; 
            await this.poll(orderNumber, orderPosition, profileIds, slots);
            //assigning
            await this.assignFilesSearch(orderNumber, orderPosition, profileIds, slots);
            slots = this.getSlotsForAnotherPoll(slots); 
            profileIds = this.selectIdsFromSlots(profileIds,slots);
        }

        await this.updateProfilesStatus(orderNumber, deliveryNoteNumber, orderPosition);
        this.setState({
            showSavingModal: false
        });
    }

    getOrderNumbersPrefix = async (orderNumberPrefix) => {
        console.log(`DEBUG[getOrderNumbersPrefix] prefix: ${orderNumberPrefix} supplier: ${this.state.supplierNumber}`);
        try {
            let supplierNumber = this.state.supplierNumber;
            if(supplierNumber) {
                this.setState({isLoadingPrefix: false});
                return (await certblockAPI.callApi('orderPrefix', [supplierNumber, orderNumberPrefix])).data;
            }
        } catch (error) {
            console.log('MARWAL DEBUG[getOrderNumbersPrefix] Error:');
            console.log(error);
            this.setState({isLoadingPrefix: false});
            this.setState({
                showAPIErrorModal: true,
                showLoadingModal: false,
                showSavingModal: false,
                apiErrorMessage: globalMessages[localStorage.getItem('language')].searchDocuments.apiErrorMessageGetOrderNumberPrefix,
                apiErrorMessageForDevelopers: JSON.stringify(error)  
            });
        }
        return [];
    }

    getOrderNumbers = async (supplierNumber) => {
        console.log(`DEBUG[getOrderNumbers] supplier: ${supplierNumber}`);
        let deliveryNoteNumber = this.state.deliveryNoteNumber; 
        // const CRT_API = certblockAPI.getCRT_API(this.getUser().id_token);
        try {
            let ordersList = (await certblockAPI.callApi('orders', [supplierNumber, deliveryNoteNumber])).data; //
            // let ordersList = (await certblockAPI.callApi('orders', [supplierNumber])).data;
            if(!ordersList) {
                this.setState({
                    showAPIErrorModal: true,
                    showLoadingModal: false,
                    showSavingModal: false,
                    apiErrorMessage: globalMessages[localStorage.getItem('language')].searchDocuments.apiErrorMessageGetOrderNumbers,
                    // apiErrorMessageForDevelopers: JSON.stringify(ordersList) 
                });
                return;
            }

            if (ordersList.length > 0) {
                this.setState({ orderNumbersForSupplier: ordersList });
                // console.log('order(s) found ... create your certificate')
            } else {
                this.setState({ showAPIErrorModal: true, apiErrorMessage: globalMessages[localStorage.getItem('language')].createCertificate.uiErrorMessageFailAPICall });
            }
        } catch (e) {
            this.setState({
                showAPIErrorModal: true,
                showLoadingModal: false,
                showSavingModal: false,
                apiErrorMessage: globalMessages[localStorage.getItem('language')].searchDocuments.apiErrorMessageGetOrderNumbers,
                apiErrorMessageForDevelopers: JSON.stringify(e) 
            });
            return;
        }
    }

    editButtonClicked = async (orderNumber,deliveryNoteNumber,orderPosition) => {
        console.log(`DEBUG[editButtonClicked] (orderNumber,deliveryNoteNumber,orderPosition): (${orderNumber},${deliveryNoteNumber},${orderPosition})`)
        let editedDNNforOrderPosition = this.state.editedDNNforOrderPosition; 
        let oldDNN = this.state.savedDNN == ''? deliveryNoteNumber : this.state.savedDNN;
        if(editedDNNforOrderPosition == orderPosition) {
            // console.log(`Edit switched off & editedDNNforOrderPosition: ${editedDNNforOrderPosition}` );
            if(deliveryNoteNumber != this.state.editableDNN)
            {
                try {
                    this.setState({
                        showSavingModal: true
                    })
                    await certblockAPI.changeDNN(orderNumber,oldDNN,this.state.editableDNN,(result)=>{
                        if(result) {
                            console.log('success');
                        }
                    })
                } catch (error){
                    console.log('DEBUG[editButtonClicked] error:');
                    console.log(error);
                }
            }
            this.setState({
                editedDNNforOrderPosition: null,
                savedDNN: this.state.editableDNN,
                showSavingModal: false
            },()=>{
                // console.log(this.state.editableDNN);
                // console.log(this.state.savedDNN);
            });
            return
        }
        this.setState({
            editedDNNforOrderPosition: orderPosition,
        });
    }

    editHiddenCheck = (orderPosition) => {
        // console.log(`DEBUG[editHiddenCheck] (orderPositionMain): (${orderPosition})`);
        return this.state.editedDNNforOrderPosition == orderPosition;
    }

//--------------------------------
    render = () => {
        return (
            <div>
                <NavBar />
                <FormErrors formErrors={this.state.formErrors} />
                {this.state.isSiemensOrSupplier &&
                    <div className="container col-xl-6 col-lg-9 col-md-12 col-sm-12">
                        {/* <form onSubmit={this.handleSubmit}>     --> not needed here and would prevent that "handleKeyUp"-event is executed/handled!*/}
                        <h2><FormattedMessage id="searchDocuments.formTitle" /></h2>

                        <div className="card" style={{ padding: 20, marginTop: 30 }}>
                            <div className="form-row">
                                {/* <div className="form-group col-md-9 col-sm-12">
                                    <label htmlFor="orderNumberInput"><FormattedMessage id="searchDocuments.formOrderNumber" /></label>
                                    <AsyncTypeahead
                                            onChange={this.handleChangeOrderNumber}
                                            id="orderNumberInput"
                                            isLoading={this.state.isLoadingPrefix}
                                            value={this.state.orderNumber}
                                            inputProps={{ className: `form-control ${this.errorClass(this.state.formErrors.orderNumber)}` }}
                                            placeholder={globalMessages[localStorage.getItem('language')].createCertificate.formOrderNumberPlaceHolder}
                                            options={this.state.orderNumbersForSupplier}
                                            onSearch={this.getOrderNumbersPrefix}
                                    />
                                </div>  */}
                                <div className="form-group col-md-12 col-sm-12">
                                    <label htmlFor="orderNumberInput"><FormattedMessage id="searchDocuments.formOrderNumber" /></label>
                                        <input 
                                            name="orderNumberInput" 
                                            type="text" 
                                            value={this.state.orderNumber} 
                                            onChange={this.handleChange}
                                            className={`form-control ${this.errorClass(this.state.formErrors.orderNumber)}`}
                                            id="orderNumberInput" 
                                            placeholder={globalMessages[localStorage.getItem('language')].createCertificate.formOrderNumberPlaceHolder} 
                                            // onSubmit={this.handleChangeOrderNumber}
                                            
                                        />
                                        {/* <div className="invalid-feedback"><FormattedMessage id="createCertificate.formOrderNumber" /> {this.state.formErrors.orderNumber}</div> */}
                                </div>
                            </div>
                        </div>

                        <div className="card" style={{padding: 20, marginTop: 30}}>
                            <div id="normalMode">
                                <label htmlFor="orderNumberInput"><FormattedMessage id="searchDocuments.formOrderAndDeliverTable" /></label>                                
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th scope="col"><FormattedMessage id="searchDocuments.formOrderAndDeliverTableOrders" /></th>
                                            <th scope="col"><FormattedMessage id="searchDocuments.formOrderAndDeliverNotes" /></th>
                                            {/* <th scope="col"><FormattedMessage id="uploadDocuments.status" /></th> */}
                                            <th scope="col" />
                                        </tr>
                                    </thead>  
                                    <tbody>
                                        {this.state.deliveryNoteNumbers.map((deliveryNoteNumbers, index) =>{
                                            return (
                                                <tr key={deliveryNoteNumbers.deliveryNoteNumber} className="pdfItemMaterialList">
                                                    <td>{this.state.orderNumber}</td>
                                                    <td>{deliveryNoteNumbers.deliveryNoteNumber}</td>
                                                    <td>
                                                        <button type="button" value={index} 
                                                            onClick={()=>{this.handleShowDocuments(this.state.orderNumber, deliveryNoteNumbers.deliveryNoteNumber)}} 
                                                            id={'showDocuments' + index} className="btn btn-info" 
                                                            // hidden={false} 
                                                            style={{ padding: 0 }} 
                                                            >
                                                                <FormattedMessage id="searchDocuments.showDocumentsButton" />
                                                        </button>
                                                    </td>
                                                </tr> 
                                            )
                                            
                                        })}
                                    </tbody>                                  
                                </table>
                            </div>
                        </div>
                        {/* { 
                            (!isSupplier) && 
                            (this.state.urlToPdf) && 
                            <div className='card' id='pdfDisplay' style={{ padding: 20, marginTop: 30 }}>
                            <div>
                                <div className="form-row">
                                    <div className="form-group col-md-9 col-sm-12">
                                        <label >{this.state.orderNumber}</label>
                                        <label >/{this.state.deliveryNoteNumber}</label>
                                        <label >/{this.state.loadedPdfOrderPosition}</label>
                                        <label >/{this.state.loadedPdfProfileId}</label>
                                    </div>
                                </div>
                            <div>
                                <PDFViewer url={this.state.urlToPdf} />
                            </div>
                            </div>
                            </div>
                        } */}
                        <div className="card" style={{padding: 20, marginTop: 30}}>
                            <div id="normalMode">
                                <label htmlFor="orderNumberInput"><FormattedMessage id="createCertificate.formMaterialFromOrderNum" /></label>                                
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th scope="col"><FormattedMessage id="uploadDocuments.formOrderPosition" /></th>
                                            <th scope="col"><FormattedMessage id="uploadDocuments.formDeliveryNoteNumber" /></th>
                                            <th scope="col"><FormattedMessage id="uploadDocuments.formAttachments" /></th>
                                            <th scope="col"><FormattedMessage id="uploadDocuments.status" /></th>
                                            <th scope="col" />
                                        </tr>
                                    </thead>  
                                    <tbody>
                                        {
                                            //1st sorting materials according to the orderPosition

                                            // this.state.materials.sort((a,b)=>{
                                            //     if(a.orderPosition < b.orderPosition) {return -1}
                                            //     return 1
                                            // }).map((material, index) =>{
                                            // let orderPositionMain = material.orderPosition; 


                                            //TODO MARWAL TESTING MAPPING ORDERPOSITIONS ONLY 
                                            this.state.orderPositions.map((orderPositionMain, index) =>{    

                                            // let numberOfUplodedDocumentsPerOrderPosition = {};
                                            // for (const [position, profiles] of Object.entries(this.state.documentProfiles)) {
                                            //     let savedDocs =  profiles.reduce((ac,el)=> el.profileAlreadySaved? ac+1 : ac, 0);
                                            //     numberOfUplodedDocumentsPerOrderPosition[position]=savedDocs;
                                            // }
                                            return (
                                                // (numberOfUplodedDocumentsPerOrderPosition[orderPositionMain] > 0) &&
                                                <tr key={orderPositionMain} className="pdfItemMaterialList">
                                                    <td >{orderPositionMain}</td>
                                                    <td>
                                                        {/* <form className='form-control'> */}
                                                        <form>
                                                            <input 
                                                                type='text' 
                                                                disabled={true} 
                                                                // value={this.state.deliveryNoteNumber} 
                                                                value={this.state.editableDNN} 
                                                                // style={{ width: '25%', maxWidth: '50%'}} 
                                                                hidden={this.editHiddenCheck(orderPositionMain)}
                                                                style={(this.state.savedDNN == orderPositionMain)? { width: '30%', backgroundColor: "lightgreen"} : {width: '30%'}}
                                                            />
                                                            <input name='newDeliveryNoteNumber'
                                                                type='text' 
                                                                disabled={false} 
                                                                contentEditable={true}
                                                                // onChange={this.handleChange}
                                                                value={this.state.editableDNN} //MARWAL TO CONTINUE
                                                                onChange={(e)=>this.handleChangeNewDeliveryNoteNumber(e,orderPositionMain)}                                                                
                                                                // style={{ width: '25%', maxWidth: '50%'}} 
                                                                hidden={!this.editHiddenCheck(orderPositionMain)}
                                                                // style={{ width: '30%', borderColor: "green"}} 
                                                                
                                                            />
                                                            <button type="button"  
                                                                className={!this.editHiddenCheck(orderPositionMain)? 
                                                                    "btn btn-info btnDeleteMaterial" :
                                                                    "btn btn-warning btnDeleteMaterial"
                                                                } 
                                                                hidden={!this.state.isSupplier}
                                                                disabled={false} style={{ padding: 2 }} 
                                                                value={index} 
                                                                onClick={()=>{
                                                                    // console.log(`Edit button clicked ${this.state.orderNumber} ${this.state.deliveryNoteNumber} ${orderPositionMain} `)
                                                                    this.editButtonClicked(this.state.orderNumber,this.state.deliveryNoteNumber,orderPositionMain);
                                                                }} 
                                                                id={'editButton' + orderPositionMain} >
                                                                    <MdEdit size={15} />
                                                            </button>
                                                        </form> 
                                                    </td>                                                    
                                                    <td>
                                                        { 
                                                        // Attachments
                                                        this.state.documentProfiles[orderPositionMain] &&
                                                        this.state.documentProfiles[orderPositionMain].map((profile, idx)=> 
                                                            {
                                                                //Supplier user case
                                                                if(this.state.isSupplier) {
                                                                    // console.log(`isSupplier: ${this.state.isSupplier}`);
                                                                    return (         
                                                                        <form key={idx}>
                                                                            <label className={profile.profileAlreadySaved? "btn btn-info" : "btn btn-secondary" } htmlFor={profile.orderPosition+profile.profileNumber+'dwn'} >{profile.profileNumber} <MdCloudDownload size="1.35em"/>         
                                                                                <input id={profile.orderPosition+profile.profileNumber+'dwn'} 
                                                                                type="button" 
                                                                                orderposition={profile.orderPosition}
                                                                                profilename={profile.profileNumber}
                                                                                onClick={(e)=>this.handleFileDownload(e,profile.orderPosition,profile.profileNumber)}  
                                                                                style={{display: 'none'}}
                                                                                disabled={!profile.profileAlreadySaved}
                                                                                />
                                                                            </label>  
                                                                            {" "}                                                                            
                                                                            <label className="btn btn-info" htmlFor={profile.orderPosition+profile.profileNumber} >{profile.profileNumber}<MdAttachFile size="1.25em"/>         
                                                                                <input id={profile.orderPosition+profile.profileNumber} 
                                                                                type="file" 
                                                                                orderposition={profile.orderPosition}
                                                                                profilename={profile.profileNumber}
                                                                                onChange={(e)=>this.handleFileChange(e,profile.orderPosition,profile.profileNumber)} 
                                                                                style={{display: 'none'}}
                                                                            />
                                                                            </label>    
                                                                        </form> 
                                                                    )   
                                                                } else {
                                                                    //Siemens user case
                                                                    return (
                                                                        <form key={idx}>
                                                                            <label className={profile.profileAlreadySaved? "btn btn-info" : "btn btn-secondary" }  htmlFor={profile.orderPosition+profile.profileNumber} >{profile.profileNumber} <MdCloudDownload size="1.35em"/>         
                                                                                <input id={profile.orderPosition+profile.profileNumber} 
                                                                                type="button" 
                                                                                orderposition={profile.orderPosition}
                                                                                profilename={profile.profileNumber}
                                                                                onClick={(e)=>this.handleFileDownload(e,profile.orderPosition,profile.profileNumber)} 
                                                                                style={{display: 'none'}}
                                                                                disabled={!profile.profileAlreadySaved}
                                                                                />
                                                                            </label>    
                                                                        </form>      
                                                                    )                                                               
                                                                }
                                                        })
                                                        }
                                                    </td>
                                                    <td>
                                                     {
                                                        
                                                        this.state.documentProfiles[orderPositionMain]
                                                        && this.state.documentProfiles[orderPositionMain].map((profile, idx)=>{
                                                            return (     
                                                                <form key={idx}>
                                                                <label className="btn" disabled={true} >
                                                                    <MdCheckBox  hidden={!profile.profileAlreadySaved} size="1.25em"/>
                                                                    <MdCheckBoxOutlineBlank  hidden={profile.profileAlreadySaved} size="1.25em"/>
                                                                </label>    
                                                                </form>
                                                            )   
                                                        })
                                                        }
                                                    </td>                                           
                                                    <td>
                                                        <button type="button" value={index}
                                                            hidden={!this.state.isSupplier}
                                                            onClick={()=>this.saveDocumentsProfiles(this.state.orderNumber, orderPositionMain)} 
                                                            id={'saveButton' + index} className="btn btn-success" 
                                                            // hidden={this.state.orderPositionSelected != material.orderPosition} 
                                                            style={{ padding: 0 }} 
                                                            disabled={this.state.deliveryNoteNumber == '' }><FormattedMessage id="uploadDocuments.saveButton" />
                                                        </button>
                                                    </td>
                                                </tr> 
                                            )  
                                        }
                                        )}
                                    </tbody>                                  
                                </table>
                            </div>
                        </div>
                    </div>
                }
                <br/>
                <div>
                    <LoadingComponent hidden={this.state.showLoadingModal} />
                </div> 
                <div>
                    <SavingComponent hidden={this.state.showSavingModal} />
                </div>                               
                <div>
                    <Modal animation={false} show={this.state.showAPIErrorModal}>
                        <ModalHeader>
                            <Modal.Title><FormattedMessage id="uploadDocuments.uiErrorMessageFailTitleAPICall" /></Modal.Title>
                        </ModalHeader>    
                        <Modal.Body>
                            <div>{this.state.apiErrorMessage}</div>
                            <div>{this.state.apiErrorMessageForDevelopers}</div>
                        </Modal.Body>    
                        <Modal.Footer>
                            <Button 
                            onClick={()=>{this.setState({showAPIErrorModal: false})}}><FormattedMessage id="uploadDocuments.closeButton" /></Button>
                        </Modal.Footer>                
                    </Modal>
                </div>         
                <Footer />
            </div >
        );
    }
}

export default SearchContainer
