import React from 'react';
import '../resources/css/footer.css';

export class Footer extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="fixed-bottom" style={{ display: 'flex', justifyContent: 'center' }}>
        <div>
        
        <a style={{ paddingRight : '20px' }} href="https://new.siemens.com/global/en.html">siemens.com Global Website</a>
        <span style={{ paddingRight : '40px' }}>&#169; Siemens 1996 - {new Date().getFullYear()}</span>
          <a style={{ paddingRight : '20px' }} href="https://new.siemens.com/global/en/general/legal.html">Corporate Information</a>
          <a style={{ paddingRight : '20px' }} href="https://new.siemens.com/global/en/general/privacy-notice.html">Privacy Notice</a>
          <a style={{ paddingRight : '20px' }} href="https://new.siemens.com/global/en/general/cookie-notice.html">Cookie Notice</a>
          <a style={{ paddingRight : '20px' }} href="https://new.siemens.com/global/en/general/terms-of-use.html">Terms of use</a>
          <a style={{ paddingRight : '20px' }} href="https://new.siemens.com/global/en/general/digital-id.html">Digital ID</a>
          </div>
    </div>
    )
  }
}

export default Footer
